export default api => {
    const uri = `/accounts`;

    return {
        getAccountTypes: async () => {
            // Get lookup from local storage if it exists
            let types = JSON.parse(localStorage.getItem("bomboraAccountTypes")) || []
            if (types.length == 0) {
                types = await api.get({ url: `${uri}/types` })
                localStorage.setItem("bomboraAccountTypes", JSON.stringify(types))
            } else {
                console.debug("Using cached account types");
            }
            return types;
        },
        getAccounts: async (listArgs) => {
            let queryParams = []
            for (const queryParam in listArgs) {
                if (listArgs[queryParam])
                    queryParams.push(`${queryParam}=${listArgs[queryParam]}`)
            }
            const url = `${uri}/list?${queryParams.join('&')}`;
            return api.get({
                url: url,
            })
                .then((res) => res)
        },
        getAccount: async (id) =>
            (await api.get({ url: `${uri}/${id}` })),
        updateAccount: async (id, account) =>
            (await api.post({ url: `${uri}/${id}`, data: account })),
        updateSurgeProduct: async (id, product) =>
            (await api.post({ url: `${uri}/${id}/products/surge`, data: product })),
        updateProduct: async (id, productName, product) =>
            (await api.post({ url: `${uri}/${id}/products/${productName}`, data: product })),
        getAccountReportFile: async () => {
            return api.get({
                url: `${uri}/report`,
                responseType: 'arraybuffer'
            })
                .then((res) => {
                    const blob = new Blob([res.data], { type: 'text/csv' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = `AccountReport-${new Date().toISOString().split('T')[0]}.csv`
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
        },
        createAccount: async (account) =>
            (await api.post({ url: `${uri}`, data: account })),
        testSFTP: async (creds) =>
            (await api.post({ url: `${uri}/testsftp`, data: creds })),
        // Type is S3 or SFTP
        checkCredentialsExist: async (id, type) =>
            (await api.get({ url: `${uri}/checkcreds/${id}/${type}` })),
        saveSftpCredentials: async (id, credentials) =>
            (await api.post({ url: `${uri}/${id}/savesftp`, data: credentials })),
        saveS3Credentials: async (id, credentials) =>
            (await api.post({ url: `${uri}/${id}/saves3`, data: credentials })),
    }
}
