<template>
  <div class="main-content">
    <div class="flex">
      <div class="flex-1">
        <card>
          <template v-slot:header>
            <column-group>
              <div>
                <h3 class="text-3xl font-bold">Users</h3>
              </div>
              <div class="justify-self-end inline-block">
                <Btn :color="this.isMouseOverButton ? 'white': '#EC7E1E'" @click="triggerModal" @mouseenter="isMouseOverButton=true" @mouseleave="isMouseOverButton=false">
                  <icon class="h-3 pb-0.5" iconType="plus" :iconColor="this.isMouseOverButton ? '#EC7E1E': 'white'"></icon>
                  Add User
                </Btn>
              </div>
            </column-group>
          </template>
          <column-group class="pb-4">
            <text-field placeholder="Search by name"
                        :fullWidth="false"
                        class="mr-3"
                        name="manageuser.nameFilter"
                        v-model="nameFilter">
              <template #suffix>
                <icon iconType="spyglass" class="w-4" />
              </template>
            </text-field>
            <dropdown placeholder="Filter by role"
                      :fullWidth="false"
                      :items="dropdownRoles"
                      name="manageuser.roleFilter"
                      v-model="roleFilter" />
          </column-group>
          <Transition>
            <alert v-if="showUserDeleteSuccess" style-type="success" class="mt-2 mb-2">
              User deleted successfully.
            </alert>
          </Transition>
          <Transition>
            <alert v-if="showUserSaveSuccess" style-type="success" class="mt-2 mb-2">
              User created successfully.
            </alert>
          </Transition>
          <Transition>
            <alert v-if="showUserLagWarning" style-type="info" class="mt-2 mb-2">
              Updates may not display for several minutes.
            </alert>
          </Transition>
          <Transition>
            <alert v-if="showUserBlockSuccess" style-type="success" class="mt-2 mb-2">
              User was blocked successfully. They will no longer be able to login to any applications.
            </alert>
          </Transition>
          <Transition>
            <alert v-if="showUserUnblockSuccess" style-type="success" class="mt-2 mb-2">
              User was unblocked successfully. It may take a couple minutes for this to take effect.
            </alert>
          </Transition>
          <Transition>
            <alert v-if="showUserBlockError" style-type="warning" class="mt-2 mb-2">
              User block status could not be updated.
            </alert>
          </Transition>
          <grid v-if="loaded && filterRows().length > 0">
            <template #gridHeaderCells>
              <grid-header-cell sortable="true">Name</grid-header-cell>
              <grid-header-cell sortable="true">Email</grid-header-cell>
              <grid-header-cell>Roles</grid-header-cell>
              <grid-header-cell column-key="contextMenu"></grid-header-cell>
            </template>
            <template #rows>
              <template v-for="(dataRow, index) in filterRows()" :key="dataRow.id">
                <grid-row>
                  <grid-cell class="cursor-pointer" @click="triggerModal(dataRow.id)">{{ dataRow.firstName }} {{ dataRow.lastName }}</grid-cell>
                  <grid-cell class="cursor-pointer" @click="triggerModal(dataRow.id)">{{ dataRow.email }}</grid-cell>
                  <grid-cell class="cursor-pointer" @click="triggerModal(dataRow.id)">{{ prettyPrintRoles(dataRow.roles) }}</grid-cell>
                  <grid-cell>
                    <button @click.prevent.stop="openContextMenu($event, dataRow)"
                            type="button"
                            class="w-4 flex justify-center focus:outline-none m-auto">
                      <img src="@/assets/kebab-menu.svg" alt="context menu icon" class="border-none self-center">
                    </button>
                  </grid-cell>
                </grid-row>
              </template>
            </template>
          </grid>
          <div v-if="!loaded" class="text-center">
            <div class="inline-block">
              <loading-spinner />
            </div>
          </div>
          <!-- display this message if there's no row data -->
          <div v-if="loaded && filterRows().length == 0" class="text-blue-darkest font-semibold text-center">
            There are no Users to display.
          </div>
        </card>
      </div>
    </div>

    <context-menu element-id="userContextMenu"
                  :options="contextOptions"
                  ref="contextMenu"
                  @option-clicked="contextOptionClicked">
    </context-menu>

    <Transition>
      <user-add-edit-modal v-if="showUserModal"
                           :user="modalUser.user"
                           :roles="modalUser.roles"
                           :account="account"
                           :isNew="isNewUser"
                           @close="modalCloseHandler"></user-add-edit-modal>
    </Transition>

    <Transition>
      <confirm-dialog v-if="showUserDeleteConfirm"
                      @close="onDeleteConfirmCancel"
                      @confirm="onDeleteConfirm"
                      title="Confirm Delete User"
                      confirmText="Delete">
        <p>You are about to delete {{contextItem.email}}.</p>
        <p>Are you sure you want to proceed?'</p>
      </confirm-dialog>
    </Transition>
  </div>
</template>

<script>
  import { Alert, Btn, Card, ColumnGroup, Dropdown, LoadingSpinner, Grid, GridHeaderCell, GridRow, GridCell, Icon, TextField } from "@bombora/component-library";

  import ContextMenu from "@/components/shared/ContextMenu";
  import ConfirmDialog from "@/components/shared/ConfirmDialog";
  import UserAddEditModal from "@/components/pages/account/UserAddEditModal";

  export default {
    name: "ManageUsers",
    components: {
      Alert,
      Btn,
      Icon,
      Card,
      Dropdown,
      ColumnGroup,
      ConfirmDialog,
      TextField,
      Grid,
      GridHeaderCell,
      GridRow,
      GridCell,
      LoadingSpinner,
      ContextMenu,
      UserAddEditModal
    },
    props: ['account', 'loading'],
    data: function () {
      return {
        rows: [],
        loaded: false,
        nameFilter: "",
        roleFilter: '',
        bomboraAccountId: 50067,
        dropdownRoles: [],
        gridParams: {
          totalPages: 0,
          totalUsers: 0,
          pageSize: 10,
          page: 1,
        },
        showUserModal: false,
        showUserDeleteConfirm: false,
        showUserLagWarning: false,
        showUserDeleteError: false,
        showUserDeleteSuccess: false,
        showUserSaveSuccess: false,
        showUserBlockSuccess: false,
        showUserUnblockSuccess: false,
        showUserBlockError: false,
        isMouseOverButton: false,
        modalUser: {
          user: {},
          roles: []
        },
        isNewUser: false,
        // This is set to track what item we are operating on for the context actions
        contextItem: {},
      };
    },
    mounted() {
      this.$api.getRoleList().then((res) => {
        this.dropdownRoles = (res.data || []).map((x) => ({
          label: x.name,
          value: x.name,
        }));
      }).catch((err) => console.error(err.message))
    },
    watch: {
      // This is because the account is not loaded when the component starts mounting. We need a slight delay.
      loading: {
        handler(newVal) {
          if (!newVal) {
            this.refreshList();
          }
        },
        // force eager callback execution
        immediate: true
      },
    },
    methods: {
      onSearch(value) {
        this.listParams.search = value;
        this.nameFilter = value;
      },
      filterRows() {
        var filtered = this.rows;
        if (this.nameFilter != '') {
          filtered = filtered.filter(x => (x.firstName ? x.firstName : "").toLowerCase().includes(this.nameFilter.toLowerCase())
            || (x.lastName ? x.lastName : "").toLowerCase().includes(this.nameFilter.toLowerCase())
            || (x.email ? x.email : "").toLowerCase().includes(this.nameFilter.toLowerCase()));
        }
        if (this.roleFilter != '') {
          filtered = filtered.filter(x => (x.roles ? x.roles : "").toLowerCase().includes(this.roleFilter.toLowerCase()));
        }

        return filtered;
      },
      refreshList() {
        this.loaded = false;
        // Add the watched search object to list params here
        this.$api.getUsers({ accountId: this.account.id })
          .then((res) => {
            this.rows = res.data || [];
            this.loaded = true;
          })
          .catch((err) => console.error(err.message))
      },
      pageNumClick(pageNum) {
        this.gridParams.page = pageNum;
      },
      prettyPrintRoles(roles) {
        return roles ? roles.replaceAll(',', ', ') : '';
      },
      triggerModal(userId) {
        if (userId == "") {
          this.isNewUser = true;
          this.modalUser = {
            user: {},
            roles: []
          };
          this.$api.getUserRoleList(this.account.id)
            .then((res) => {
              this.modalUser.roles = res.data;
              this.showUserModal = true;
            })
            .catch((err) => console.error(err.message));
        } else {
          this.isNewUser = false;
          this.$api.getUser(userId, this.account.id)
            .then((res) => {
              this.modalUser = res.data;
              this.showUserModal = true;
            })
            .catch((err) => console.error(err.message));
        }
      },
      modalCloseHandler({ dirtyClose, userCreated }) {
        this.showUserModal = false;
        if (dirtyClose) {
          this.refreshList();
        }
        if (userCreated) {
          this.showUserSaveSuccess = true;
          setTimeout(() => this.showUserSaveSuccess = false, 7000);
        }
        this.showUserLagWarning = true;
        setTimeout(() => this.showUserLagWarning = false, 7000);
      },
      openContextMenu(event, user) {
        this.$refs.contextMenu.showMenu(event, user);
      },
      contextOptionClicked(event) {
        if (event.option.slug == "block-unblock") {
          this.blockUnblockUser(event.item);
        }
        if (event.option.slug == "delete") {
          this.showDeleteConfirm(event.item);
        }
      },
      showDeleteConfirm(user) {
        this.contextItem = user;
        this.showUserDeleteConfirm = true;
      },
      onDeleteConfirm() {
        this.deleteUser(this.contextItem.id);
        this.showUserDeleteConfirm = false;
        this.contextItem = {};
      },
      onDeleteConfirmCancel() {
        this.contextItem = {};
        this.showUserDeleteConfirm = false;
      },
      deleteUser(userId) {
        this.$api.deleteUser(userId, this.account.id)
          .then(() => {
            var index = this.rows.indexOf(this.contextItem);
            this.rows.splice(index, 1);
            // scroll top and pop info message
            window.scrollTo(0, 0);
            this.showUserLagWarning = true;
            setTimeout(() => this.showUserLagWarning = false, 7000);
            this.showUserDeleteSuccess = true;
            setTimeout(() => this.showUserDeleteSuccess = false, 7000);
          })
          .catch((err) => {
            console.error(err.message);
            // We currently do not have error messages that can float. (toasts) THIS DOES NOT DISPLAY ANYTHING
            this.showUserDeleteError = true;
            setTimeout(() => this.showUserDeleteError = false, 7000);
          });
      },
      blockUnblockUser(user) {
        var index = this.rows.indexOf(user);
        var currentBlockState = this.rows[index].blocked ?? false;
        var newBlockState = !currentBlockState;
        // This is a boolean flag on the user object, the api call will just toggle the flag
        this.$api.toggleUserBlock(user.id, { blocked: newBlockState })
          .then((res) => {
            this.rows[index].blocked = res.data.user.blocked;
            this.showUserLagWarning = true;
            setTimeout(() => this.showUserLagWarning = false, 7000);
            if (res.data.user.blocked) {
              this.showUserBlockSuccess = true;
              setTimeout(() => this.showUserBlockSuccess = false, 7000);
            } else {
              this.showUserUnblockSuccess = true;
              setTimeout(() => this.showUserUnblockSuccess = false, 7000);
            }
          })
          .catch((err) => {
            // We currently do not have error messages that can float. (toasts) THIS DOES NOT DISPLAY ANYTHING
            this.showUserBlockError = true;
            setTimeout(() => this.showUserBlockError = false, 7000);
          });
      },
      findUserRecord(user) {
        return this.rows.filter(x => x.id == user.id)[0];
      }
    },
    computed: {
      currentPage() {
        return this.gridParams.page
      },
      contextOptions() {
        if (this.account.id == this.bomboraAccountId) {
          return [
            {
              name: 'Delete',
              slug: 'delete',
            },
          ]
        } else {
          return [
            {
              name(user) { return user.blocked ? 'Unblock' : 'Block' },
              slug: 'block-unblock',
            },
            {
              type: 'divider',
            },
            {
              name: 'Delete',
              slug: 'delete',
            },
          ]
        }
      },
    }
  };
</script>

<style scoped></style>
