export default api => {
    const uri = '/domainappend'

    return {
        uploadReport: async (formData) => {
            return (await api.postForm({ url: `${uri}`, data: formData }));
        },
        getReports: async () => {
            return (await api.get({ url: `${uri}`}).then((res) => res))
        },
        downloadReport: async (id) => {
            return (await api.get({
                url: `${uri}/${id}`
            })
                .then((res) => {
                    if(res.status === 200) {
                        const blob = new Blob([res.data], { type: 'text/csv' })
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = res.headers['content-disposition'].split('filename=')[1].split(';')[0];
                        link.click()
                        URL.revokeObjectURL(link.href)
                        return res;
                    } else {
                        return res;
                    }
            }))
        }
    }
}
