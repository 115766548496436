<template>
    <p class="text-sm text-red" v-if="errorMessage != ''">
        {{errorMessage}}
    </p>
</template>

<script>
    export default {
        name: "FieldErrorMessage",
        props: {
            field: {
                required: true,
            },
            // This property is only used to support collection error messages. 
            // Vuelidate has a separate structure for collection errors
            collectionFieldName: {
                type: String,
                default: null
            }
        },
        computed: {
            errorAccessor() {
                // This is required for collections because vuelidate will return a separate structure for collection errors
                if (this.field?.$each && this.collectionFieldName) {
                    return this.field?.$each.$response.$errors ?? null
                } else {
                    return this.field?.$errors ?? null
                }
            },
            errorMessage() {
                if (this.errorAccessor && this.errorAccessor.length > 0) {
                    // The index of the error on each array will always be 0 unless there are multiple validators on a single field.
                    // Single value:        this.field.$errors[{index of error}] = error state including message
                    // Collection value:    this.field.$each.$response.$errors[{index of error on collection}][{name of property}][{index of error on property}] = error state including message
                    if (this.collectionFieldName) {
                        // Because collection errors are in an array and have the same message per collectionFieldName, we can pull any out and display them.
                        // This will pull out the first message in the array for an error from this collection field name
                        var errorMessage = this.errorAccessor.filter(x => x[this.collectionFieldName].length > 0 && x[this.collectionFieldName][0]?.$message != '');
                        if (errorMessage.length > 0) {
                            return errorMessage[0][this.collectionFieldName][0]?.$message ?? '';
                        }
                        return '';
                    } else {
                        return this.errorAccessor[0].$message ?? ''
                    }
                }
            }
        }
    };
</script>

<style lang="css" scoped>
</style>
