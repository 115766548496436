export default api => {
    const uri = `/users`;

    return {
        getUsers: async (listArgs) => {
            let queryParams = []
            for (const queryParam in listArgs) {
                if (listArgs[queryParam])
                    queryParams.push(`${queryParam}=${listArgs[queryParam]}`)
            }
            const url = `${uri}/list?${queryParams.join('&')}`;
            return api.get({
                url: url,
            })
                .then((res) => res)
        },
        getUser: async (userId, accountId) =>
            (await api.get({url: `${uri}/${userId}/${accountId}`,})),
        getUserRoleList: async (accountId) =>
            (await api.get({url: `${uri}/GetRoleList/${accountId}`,})),
        getRoleList: async () =>
            (await api.get({url: `${uri}/roleslist`,})),
        triggerPasswordReset: async (email) =>
            (await api.post({url: `${uri}/triggerpasswordreset?email=${email}`})),
        createUser: async (userCreate) =>
            (await api.post({ url: `${uri}`, data: userCreate })),
        updateUser: async (userId, user) =>
            (await api.patch({ url: `${uri}/${userId}`, data: user })),
        toggleUserBlock: async (userId, user) =>
            (await api.patch({ url: `${uri}/${userId}/toggleblock`, data: user })),
        deleteUser: async (userId, accountId) =>
            (await api.delete({ url: `${uri}/${userId}/${accountId}` })),
        getUserReportFile: async () => {
            return api.get({
                url: `${uri}/report`,
                responseType: 'arraybuffer'
            })
                .then((res) => {
                    const blob = new Blob([res.data], { type: 'text/csv' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = `UserReport-${new Date().toISOString().split('T')[0]}.csv`
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
        }
    }
}
