<template>
  <div :class="[
      'flex input-field-container min-w-input border border-solid border-blue-gray rounded gap-1 h-10 pl-2 bg-white',
      { disabled: disabled },
    ]">
    <span v-if="prefix"
          class="inline-flex items-center pl-1 pr-3 mr-3 text-sm text-gray border-r border-blue-gray">{{ prefix }}</span>
    <input type="number"
           :name="name"
           :value="modelValue"
           :class="[
        'flex-1 text-field text-sm font-sans font-normal w-auto h-auto placeholder-gray-light w-auto transition ease-in-out delay-75',
        { 'w-full': fullWidth },
      ]"
           :placeholder="placeholder"
           :disabled="disabled"
           @input="$emit('update:modelValue', $event.target.value)"
           @blur="$emit('blur')" />
    <span v-if="hasSuffix"
          class="inline-flex items-center min-w-max pl-3 pr-3 ml-3 text-sm text-gray border-r border-blue-gray">
      <slot name="suffix"></slot>
    </span>
  </div>
</template>

<script>
  export default {
    name: "TextField",
    emits: ["update:modelValue", "blur"],
    props: {
      modelValue: {
        type: Number,
        default: 0,
        required: true,
      },
      // This is an exception to the no-content-in-props rule for developer experience
      placeholder: {
        type: String,
        default: "",
      },
      // This is an exception to the no-content-in-props rule for developer experience
      prefix: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      fullWidth: {
        type: Boolean,
        default: true,
      },
      name: {
        type: String,
        default: "",
      },
    },
    computed: {
      hasSuffix() {
        return !!this.$slots.suffix;
      },
    },
    methods: {},
  };
</script>

<style lang="css" scoped>
  .input-field-container {
    grid-template-columns: auto 35px;
  }

    .input-field-container:focus-within,
    .input-field-container:hover {
      @apply border border-solid border-blue-light rounded;
    }

    .input-field-container.disabled {
      @apply text-gray-light bg-gray-lightest border border-blue-gray;
    }

  .text-field:focus {
    @apply border border-none outline-none;
  }

  .text-field:disabled {
    @apply text-gray-light bg-gray-lightest;
  }
</style>
