<template>
  <label class="font-bold leading-4">
    {{ label }}
    <span v-if="required">*</span>
    <tooltip v-if="hasHelpText">
      <slot name="helpText"></slot>
    </tooltip>
  </label>
  <slot></slot>
  <slot name="validation-message"></slot>
</template>
<script>
  import Tooltip from "@bombora/component-library/dist/Tooltip";

  export default {
    name: "ValidatedVertFieldGroup",
    components: {
      Tooltip,
    },
    props: {
      // This is an exception to the no-content-in-props rule for developer experience
      label: {
        type: String,
        default: "MISSING FIELD LABEL",
        required: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      hasHelpText() {
        return !!this.$slots.helpText;
      }
    },
  };
</script>

<style lang="css" scoped></style>
