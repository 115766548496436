<template>
  <div class="main-content">
    <div class="flex">
      <div class="flex-1">
        <card>
          <template v-slot:header>
            <column-group :cols="2">
              <div>
                <h3 class="text-3xl font-bold">Shipper Administration</h3>
                <p class="text-dark-gray">All dates are Coordinated Universal Time (UTC). You can only Retry shipments in a status of Error or Done.</p>
              </div>
              <div class="justify-self-end inline-block col-span-1">
                <Btn color="orange" @click="retrySelected">
                  Retry Selected
                </Btn>
              </div>
            </column-group>
          </template>
          <column-group class="pb-4">
            <column-group>
              <dropdown name="statusList"
                        :fullWidth="true"
                        :items="statusList"
                        v-model="listParams.statusId"
                        class="mr-3" />
              <date-picker v-model="listParams.capturedDateRange" :enable-range="true" />
            </column-group>
            <text-field placeholder="Search Containers..."
                        name="searchNameOrId"
                        :fullWidth="true"
                        class="ml-12"
                        v-model="listParams.searchTerm"
                        :modelValue="listParams.searchTerm"
                        @update:modelValue="onSearch">
              <template #suffix>
                <icon iconType="spyglass" class="w-4" />
              </template>
            </text-field>
          </column-group>
          <div v-if="isError" class="pb-4">
            <Transition>
              <alert style-type="warning" class="mt-4">
                {{errorMessage}}
              </alert>
            </Transition>
          </div>
          <div v-if="isSuccess" class="pb-4">
            <Transition>
              <alert style-type="success" class="mt-4">
                {{successMessage}}
              </alert>
            </Transition>
          </div>
          <grid v-if="loaded && rows.length > 0" class="text-xs">
            <template #gridHeaderCells>
              <grid-header-cell :sortable="false">
                <input type="checkbox" :checked="isAllSelected" @change="toggleSelectAll" />
              </grid-header-cell>
              <grid-header-cell :sortable="true" :sortDirection="idSortOrder" @table-sorted="() => onSort('id', 'idSortOrder')">Id</grid-header-cell>
              <grid-header-cell :sortable="true" :sortDirection="runDateSortOrder" @table-sorted="() => onSort('run_date', 'runDateSortOrder')">Run Date</grid-header-cell>
              <grid-header-cell :sortable="true" :sortDirection="statusSortOrder" @table-sorted="() => onSort('status', 'statusSortOrder')">Status</grid-header-cell>
              <grid-header-cell :sortable="true" :sortDirection="containerSortOrder" @table-sorted="() => onSort('container', 'containerSortOrder')">Container</grid-header-cell>
              <grid-header-cell :sortable="true" :sortDirection="fileNameSortOrder" @table-sorted="() => onSort('file_name', 'fileNameSortOrder')">File Name</grid-header-cell>
              <grid-header-cell :sortable="true" :sortDirection="parentIdSortOrder" @table-sorted="() => onSort('parent_id', 'parentIdSortOrder')">Parent Id</grid-header-cell>
              <grid-header-cell :sortable="true" :sortDirection="attemptsSortOrder" @table-sorted="() => onSort('attempts', 'attemptsSortOrder')">Attempts</grid-header-cell>
              <grid-header-cell :sortable="true" :sortDirection="errorSortOrder" @table-sorted="() => onSort('error', 'errorSortOrder')">Error</grid-header-cell>
            </template>
            <template #rows>
              <template v-for="(dataRow, index) in rows" :key="dataRow.id" :class="dataRow.status === 'Done' ? 'bg-gray-200 opacity-50 cursor-not-allowed' : ''">
                <grid-row>
                  <grid-cell class="cursor-pointer">
                    <input type="checkbox" :value="dataRow" :disabled="isRetry(dataRow.status)" v-model="selectedRows" />
                  </grid-cell>
                  <grid-cell>{{ dataRow.id }}</grid-cell>
                  <grid-cell><div class="w-32 break-words">{{ tryGetDateTimeString(dataRow.runDate, true) }}</div></grid-cell>
                  <grid-cell><div class="w-20 text-center">{{ dataRow.status }}</div></grid-cell>
                  <grid-cell><div class="w-32 break-words">{{ dataRow.container }}</div></grid-cell>
                  <grid-cell><div class="w-32 break-words">{{ dataRow.fileName }}</div></grid-cell>
                  <grid-cell><div class="w-24 break-words">{{ dataRow.parentId }}</div></grid-cell>
                  <grid-cell><div class="w-24 text-center">{{ dataRow.attempts }}</div></grid-cell>
                  <grid-cell><div class="w-40 break-words">{{ dataRow.error }}</div></grid-cell>
                </grid-row>
              </template>
            </template>
          </grid>
          <div v-if="!loaded" class="text-center">
            <div class="inline-block">
              <loading-spinner />
            </div>
          </div>
          <!-- display this message if there's no row data -->
          <div v-if="loaded && rows.length == 0" class="text-blue-darkest font-semibold text-center">There are no shipments to display.</div>
          <template #footer>
            <grid-pager v-if="totalPages > 1"
                        :page-size="listParams.rows"
                        :total-pages="totalPages"
                        :current-page="listParams.page"
                        @paged="pageNumClick"></grid-pager>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Alert,
    Btn,
    Card,
    ColumnGroup,
    Dropdown,
    DatePicker,
    Grid,
    GridCell,
    GridHeaderCell,
    GridPager,
    GridRow,
    Icon,
    LoadingSpinner,
    SpinnerIcon,
    TextField
  } from "@bombora/component-library";

  import { debounce, tryGetDateTimeString } from "../../helpers.js";

  export default {
    name: "Shipper",
    components: {
      Alert,
      Btn,
      Card,
      ColumnGroup,
      Dropdown,
      DatePicker,
      Grid,
      GridCell,
      GridHeaderCell,
      GridPager,
      GridRow,
      Icon,
      LoadingSpinner,
      SpinnerIcon,
      TextField
    },
    props: {},
    data: function () {
      return {
        errorMessages: {
          shipmentLoadError: 'There\'s an issue loading shipment data.  Please try again later.',
          shipmentRetryError: 'There\'s an issue attempting to retry the selected shipments.  Please try again later.',
          shipmentSelectionError: 'Please select a shipment.',
          dateRangeError: 'Date range is required.'
        },
        selectedRows: [],
        rows: [],
        loaded: false,
        statusList: [
          {
            label: "All",
            value: "0",
          },
          {
            label: "Done",
            value: "4",
          },
          {
            label: "Error",
            value: "5",
          },
          {
            label: "In Process",
            value: "2",
          },
          {
            label: "Ready",
            value: "1",
          },
          {
            label: "Waiting",
            value: "3",
          },
        ],
        listParams: {
          statusId: "0", //Default "All"
          rows: 10,
          page: 1,
          orderBy: "run_date", 
          orderByDirection: "desc",
          searchTerm: "",
          capturedDateRange: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date()]  //default [Yesterday - Today]
        },
        queryParams: {
          statusId: null,
          rows: 0,
          page: 0,
          orderBy: null, 
          orderByDirection: null,
          searchTerm: null,
          startDate: null,
          endDate: null
        },
        startDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(), //Yesterday
        endDate: new Date().toISOString(), //Today
        totalPages: 0,
        isWatching: true,
        isError: false,
        errorMessage: null,
        isSuccess: false,
        successMessage: null,
        idSortOrder: null,
        runDateSortOrder: "desc",
        statusSortOrder: null,
        containerSortOrder: null,
        fileNameSortOrder: null,
        parentIdSortOrder: null,
        attemptsSortOrder: null,
        errorSortOrder: null
      };
    },
    mounted() {
      this.refreshList();
    },
    watch: {
      listParams: {
        handler: debounce(function () {
          this.refreshList()
        }, 500),
        deep: true
      }
    },
    methods: {
      toggleSelectAll(event) {
        if (event.target.checked) {
          // Select all items except ones with status "Ready"
          this.selectedRows = this.rows.filter(row => row.status === "Done" || row.status === "Error");
        } else {
          // Deselect all items
          this.selectedRows = [];
        }
      },
      onSearch(value) {
        // Set the search value to trigger the watch.
        this.listParams.searchTerm = value;
        this.listParams.page = 1;
      },
      retrySelected() {
        let ids = this.selectedRows.map(item => item.id).join(',');
        if (ids === "") {
          this.isError = true;
          this.errorMessage = this.errorMessages['shipmentSelectionError'];
        }
        else {
          this.$api.submitRetry({ ids })
            .then(() => {
              this.isSuccess = true;
              this.successMessage = "Shipments have been successfully submitted for retry."
              this.refreshList();
            })
            .catch((err) => {
                this.isError = true;
                this.errorMessage = this.errorMessages['shipmentRetryError'];
            })
        }
        setTimeout(() => { this.isError = false }, 8000);
        setTimeout(() => { this.isSuccess = false }, 8000);
      },
      refreshList() {
        this.selectedRows = [];
        this.queryParams.statusId = this.listParams.statusId;
        this.queryParams.rows = this.listParams.rows;
        this.queryParams.page = this.listParams.page;
        this.queryParams.orderBy = this.listParams.orderBy;
        this.queryParams.orderByDirection = this.listParams.orderByDirection;
        this.queryParams.searchTerm = this.listParams.searchTerm;

        if (this.listParams?.capturedDateRange?.length === 2) {
          this.loaded = false;

          this.queryParams.startDate = this.listParams.capturedDateRange[0].toISOString();
          this.queryParams.endDate = this.listParams.capturedDateRange[1].toISOString();

          this.$api.getShipperList(this.queryParams)
            .then((res) => {
              this.rows = res.data.shipments || []; //Result to display
              this.listParams.page = res.data.page;//Page number to display
              this.listParams.rows = 10; //How many rows to display

              if (this.rows === 0) {
                this.listParams.page = 0;
                this.totalPages = 0;
              }
              else {
                this.totalPages = Math.ceil(res.data.total / this.listParams.rows);
              }
              this.loaded = true;
            })
            .catch((err) => {
              this.isError = true;
              this.errorMessage = this.errorMessages['shipmentLoadError'];
              setTimeout(() => { this.isError = false }, 8000);
            })
        } else {
          this.isError = true;
          this.errorMessage = this.errorMessages['dateRangeError'];
          setTimeout(() => { this.isError = false }, 8000);
        }
      },
      onSort(field, sortOrderKey) {
        let newSort = this[sortOrderKey] === "desc" ? "asc" : "desc";
        this.clearSort();
        this[sortOrderKey] = newSort;

        this.listParams.orderBy = field;
        this.listParams.orderByDirection = newSort;
      },
      clearSort() {
         this.idSortOrder = null,
         this.runDateSortOrder = null,
         this.statusSortOrder = null,
         this.containerSortOrder = null,
         this.fileNameSortOrder = null,
         this.parentIdSortOrder = null,
         this.attemptsSortOrder = null,
         this.errorSortOrder = null
      },
      isRetry(status) {
        const disabledStates = ["In Process", "Ready", "Waiting"];
        return disabledStates.includes(status);
      },
      pageNumClick(pageNum) {
        this.listParams.page = pageNum;

      },
      tryGetDateTimeString: tryGetDateTimeString
    },
    computed: {
      isAllSelected() {
        return this.selectedRows.length === this.rows.length && this.rows.length > 0;
      }
    }
  };
</script>

<style scoped></style>
