<template>
  <confirm-dialog v-if="show"
                  @close="onCancel"
                  @confirm="onConfirm"
                  title="Confirm Product Disable"
                  confirmText="Disable">
    <p>You are about to disable {{productName}} for this account.</p>
    <p>Are you sure you want to proceed?'</p>
  </confirm-dialog>
</template>

<script>
  import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "ProductDisableConfirmModal",
  components: {
    ConfirmDialog,
  },
  emits: ["cancel", "confirm"],
  props: {
    productName: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    onCancel() {
      this.$emit("cancel")
    },
    onConfirm() {
      this.$emit("confirm")
    }
  }
};
</script>
