<template>
  <div class="main-content">
    <div class="flex">
      <div class="flex-1">
        <card>
          <template v-slot:header>
            <column-group :cols="3">
              <div>
                <h3 class="text-2xl font-bold">Keyword to Topic Cluster</h3>
                <p class="text-dark-gray">All dates are local time.</p>
              </div>
              <div class="justify-self-end inline-block col-span-2">
                <Btn color="orange" @click="triggerModal">Upload CSV</Btn>
                <Btn color="blue-line" :class="{ disabled: refreshing }" :disabled="refreshing" @click="handleRefreshClick">
                  <spinner-icon v-if="refreshing" width="16" height="16"></spinner-icon> Refresh Table
                </Btn>
              </div>
            </column-group>
          </template>
          <div v-if="downloadError" class="pb-4">
            <Transition>
              <alert style-type="warning" class="mt-4">
                {{downloadErrorMessage}}
              </alert>
            </Transition>
          </div>

          <grid v-if="loaded && rows.length > 0">
            <template #gridHeaderCells class="bg-blue-darkest">
              <grid-header-cell column-key="Id" :sortable="true" :sort-direction="listParams.sortById" @table-sorted="sort('sortById')">Id</grid-header-cell>
              <grid-header-cell column-key="CreatedDate" :sortable="true" :sort-direction="listParams.sortByDate" @table-sorted="sort('sortByDate')">Created Date</grid-header-cell>
              <grid-header-cell column-key="FileName" :sortable="true" :sort-direction="listParams.sortByFileName" @table-sorted="sort('sortByFileName')">File Name</grid-header-cell>
              <grid-header-cell column-key="Status" :sortable="true" :sort-direction="listParams.sortByStatus" @table-sorted="sort('sortByStatus')">Status</grid-header-cell>
              <grid-header-cell column-key="Email" :sortable="true" :sort-direction="listParams.sortByEmail" @table-sorted="sort('sortByEmail')">Email</grid-header-cell>
              <grid-header-cell column-key="TotalKeywords" :sortable="false">Total Keywords</grid-header-cell>
              <grid-header-cell column-key="MatchedTopics" :sortable="false">Matched Topics</grid-header-cell>
              <grid-header-cell column-key="Download" :sortable="false"></grid-header-cell>
            </template>
            <template #rows>
              <template v-for="(dataRow, index) in paginatedResults" :key="dataRow.id">
                <grid-row>
                  <grid-cell>{{ dataRow.requestId }}</grid-cell>
                  <grid-cell>{{ tryGetDateTimeString(dataRow.createdDateUtc, false) }}</grid-cell>
                  <grid-cell>
                    <ellipsis-text-block :text="dataRow.fileName" :length=19></ellipsis-text-block>
                  </grid-cell>
                  <grid-cell>
                    <div class="w-24 text-center border-2 rounded text-sm"
                         :class="{'bg-green-lightest text-green' : dataRow.status === 'Complete',
                    'bg-blue-light text-blue': dataRow.status === 'In Progress',
                    'bg-red-light text-red': dataRow.status === 'Failed'}">{{ dataRow.status }}</div>
                  </grid-cell>
                  <grid-cell>
                    <ellipsis-text-block :text="dataRow.email" :length=19></ellipsis-text-block>
                  </grid-cell>
                  <grid-cell>{{ dataRow.totalKeywordCount }}</grid-cell>
                  <grid-cell>{{ dataRow.matchedTopicCount }}</grid-cell>
                  <grid-cell>
                    <button>
                      <icon icon-type="download" class="w-4 h-4 text-blue-darkest" @click="downloadFile(dataRow.requestId)"></icon>
                    </button>
                  </grid-cell>
                </grid-row>
              </template>
            </template>
          </grid>
          <div v-if="!loaded" class="text-center">
            <div class="inline-block">
              <loading-spinner />
            </div>
          </div>
          <!-- display this message if there's no row data -->
          <div v-if="loaded && rows.length == 0" class="text-blue-darkest font-semibold text-center">There are no records to display.</div>
          <template #footer>
            <grid-pager v-if="totalPages > 1"
                        :page-size="pageSize"
                        :total-pages="totalPages"
                        :current-page="currentPage"
                        @paged="pageNumClick"></grid-pager>
          </template>
        </card>
      </div>
    </div>

    <Transition>
      <modal v-if="showUploadModal" @close="handleClose">
        <form @submit.prevent="uploadKeywordFile">
          <div class="px-5 mb-4">
            <!-- Modal Header-->
            <div class="mb-5">
              <h1 class="text-2xl flex font-bold">Upload Keyword File</h1>
              <p class="text-gray">Files with the following characters in the name will be rejected: &lt; &gt; / \ : * ? " |</p>
            </div>
            <!-- User Information Text Inputs -->
            <div class="mb-4">
              <div class="flex flex-row">
                <div class="h-40 w-full mb-6">
                  <file-drop-zone data-id="domain-upload-action" @updated="onFileUploaded" :accept="'.csv'">
                    <div class="w-full h-full grid place-content-center">
                      <div class="w-8 h-8 place-self-center">
                        <icon icon-type="upload" class="w-6 h-6"></icon>
                      </div>
                      <p class="text-dark-gray">Click to upload or drag and drop your CSV files</p>
                    </div>
                  </file-drop-zone>
                </div>
              </div>
              <div>
                <p class="text-dark-gray">Maximum number of keywords: 500</p>
              </div>
            </div>
            <div v-if="currentFileName !==  null">
              <div class="p-2 border-dark-gray bg-gray-lightest mb-5 rounded">
                {{currentFileName}}
              </div>
            </div>

            <div class="w-full left-0 bottom-0">
              <div class="my-2">
                <btn type="submit" color="orange">
                  Upload File
                </btn>
                <Transition>
                  <alert v-if="saveSuccess" style-type="success" class="mt-4">
                    File uploaded successfully.
                  </alert>
                </Transition>
                <Transition>
                  <alert v-if="saveFailed" style-type="warning" class="mt-4">
                    {{uploadErrorMessage}}
                  </alert>
                </Transition>
              </div>
            </div>
          </div>
        </form>
      </modal>
    </Transition>
  </div>
</template>

<script>
  import {
    Alert,
    Btn,
    Card,
    ColumnGroup,
    Dropdown,
    Grid,
    GridCell,
    GridHeaderCell,
    GridPager,
    GridRow,
    LoadingSpinner,
    SpinnerIcon,
    TextField,
    Toggle,
    Modal,
  } from "@bombora/component-library";
  import Icon from "@/components/shared/Icon.vue";
  import FileDropZone from "@/components/shared/FileDropZone.vue";
  import EllipsisTextBlock from "@/components/shared/EllipsisTextBlock.vue";

  import { tryGetDateTimeString } from "@/helpers.js";

  export default {
    name: "KeywordToTopic",
    components: {
      Alert,
      Btn,
      Card,
      ColumnGroup,
      Dropdown,
      Grid,
      GridCell,
      GridHeaderCell,
      GridPager,
      GridRow,
      Icon,
      LoadingSpinner,
      SpinnerIcon,
      TextField,
      Toggle,
      Modal,
      FileDropZone,
      EllipsisTextBlock,
    },
    props: {},
    data: function () {
      return {
        rows: [],
        files: [],
        currentPage: 1,
        pageSize: 10,
        loaded: false,
        showUploadModal: false,
        saveSuccess: false,
        saveFailed: false,
        currentFileName: null,
        fileToUpload: null,
        uploading: false,
        refreshing: false,
        defaultMessage: 'There was a problem uploading your keyword file. Please try again later.',
        uploadErrorMessage: this.defaultMessage,
        downloadError: false,
        downloadErrorMessage: this.downloadDefaultMessage,
        downloadDefaultMessage: 'There was a problem downloading your file. Please try again later.',
        listParams: {
          sortById: null,      // Will be set to "asc" or "desc" if user sorts by column
          sortByFileName: null,
          sortByEmail: null,
          sortByStatus: null,
          sortByDate: "desc" //default sorting is by Date descending
        },
        sortProperties: [         // The property names to set on listParams that are sent as query parameters to the backend
          "sortById",
          "sortByFileName",
          "sortByEmail",
          "sortByStatus",
          "sortByDate"
        ],

      };
    },
    mounted() {
      this.refreshTable()
    },
    computed: {
      totalPages() {
        return Math.ceil(this.rows.length / this.pageSize)
      },
      paginatedResults() {
        return this.rows.slice(
          (this.currentPage - 1) * this.pageSize,
          (this.currentPage - 1) * this.pageSize + this.pageSize
        )
      },
    },
    methods: {
      onFileUploaded(files) {
        this.files = [];
        files.forEach(csv => {
          this.files.push(csv)
        })
        this.fileToUpload = this.files[0];
        this.currentFileName = this.files[0].name;
      },
      async uploadKeywordFile() {
        if (this.fileToUpload === null) {
          this.uploadErrorMessage = "Please provide a file to upload."
          this.saveFailed = true;
          setTimeout(() => { this.saveFailed = false }, 5000);
          return;
        }

        if (/[<>]/.test(this.currentFileName)) {
          this.uploadErrorMessage = "The file name cannot contain either < or > characters.";
          this.saveFailed = true;
          setTimeout(() => { this.saveFailed = false }, 5000);
          return;
        }

        this.uploading = true;

        let keywordsForm = new FormData();
        keywordsForm.append("keywordFile", this.fileToUpload);
        keywordsForm.append("email", this.$auth.user.value.email);

        let response = await this.$api.submitRequest(keywordsForm);
        if (response.data.success) {
          this.saveSuccess = true;
          await this.refreshTable();
          setTimeout(() => { this.saveSuccess = false }, 5000);
        } else {
          this.uploadErrorMessage = response.data.message;
          this.saveFailed = true;
          setTimeout(() => { this.saveFailed = false }, 5000);
        }
        this.currentFileName = null;
        this.fileToUpload = null;
        this.files = [];
        this.uploading = false;
      },

      async downloadFile(id) {
        let response = await this.$api.downloadFile(id);
        if (response.success === false) {
          if (response.message != null) {
            this.downloadErrorMessage = response.message;
          }
          this.downloadError = true;
          setTimeout(() => { this.downloadError = false }, 5000);

        }
      },
      sort(sortProperty) {
        this.sortProperties.forEach(prop => {
          if (prop === sortProperty)
            this.listParams[prop] = this.listParams[prop] === "asc" ? "desc" : "asc";
          else
            this.listParams[prop] = null;

          if (this.listParams[prop] === "asc") {
            this.sortByAsc(sortProperty);
          }
          else if (this.listParams[prop] === "desc") {
            this.sortByDesc(sortProperty);
          }
        });
      },
      sortByAsc(sortProperty) {
        switch (sortProperty) {
          case "sortById":
            this.rows.sort((a, b) => {
              return a.requestId > b.requestId ? 1 : a.requestId < b.requestId ? -1 : 0
            })
            break;
          case "sortByDate":
            this.rows.sort((a, b) => {
              return a.createdDateUtc > b.createdDateUtc ? 1 : a.createdDateUtc < b.createdDateUtc ? -1 : 0
            })
            break;
          case "sortByEmail":
            this.rows.sort((a, b) => {
              return a.email.toLowerCase() > b.email.toLowerCase() ? 1 : a.email.toLowerCase() < b.email.toLowerCase() ? -1 : 0
            })
            break;
          case "sortByFileName":
            this.rows.sort((a, b) => {
              return a.fileName.toLowerCase() > b.fileName.toLowerCase() ? 1 : a.fileName.toLowerCase() < b.fileName.toLowerCase() ? -1 : 0
            })
            break;
          case "sortByStatus":
            this.rows.sort((a, b) => {
              return a.status.toLowerCase() > b.status.toLowerCase() ? 1 : a.status.toLowerCase() < b.status.toLowerCase() ? -1 : 0
            })
            break;
        }
      },
      sortByDesc(sortProperty) {
        switch (sortProperty) {
          case "sortById":
            this.rows.sort((a, b) => {
              return a.requestId < b.requestId ? 1 : a.requestId > b.requestId ? -1 : 0
            })
            break;
          case "sortByDate":
            this.rows.sort((a, b) => {
              return a.createdDateUtc < b.createdDateUtc ? 1 : a.createdDateUtc > b.createdDateUtc ? -1 : 0
            })
            break;
          case "sortByEmail":
            this.rows.sort((a, b) => {
              return a.email.toLowerCase() < b.email.toLowerCase() ? 1 : a.email.toLowerCase() > b.email.toLowerCase() ? -1 : 0
            })
            break;
          case "sortByFileName":
            this.rows.sort((a, b) => {
              return a.fileName.toLowerCase() < b.fileName.toLowerCase() ? 1 : a.fileName.toLowerCase() > b.fileName.toLowerCase() ? -1 : 0
            })
            break;
          case "sortByStatus":
            this.rows.sort((a, b) => {
              return a.status.toLowerCase() < b.status.toLowerCase() ? 1 : a.status.toLowerCase() > b.status.toLowerCase() ? -1 : 0
            })
            break;
        }
      },
      async handleRefreshClick() {
        this.refreshing = true;
        await this.refreshTable();
        this.refreshing = false;
      },
      triggerModal() {
        this.showUploadModal = true;
      },
      handleClose() {
        this.showUploadModal = false;
      },
      async refreshTable() {
        this.loaded = false;
        let response = await this.$api.getRequests();
        this.rows = response.data.reports;
        //reset sorting
        this.listParams.sortByDate = "desc";
        this.listParams.sortById = null;
        this.listParams.sortByStatus = null;
        this.listParams.sortByEmail = null;
        this.listParams.sortByFileName = null;
        //sort by date
        this.sortByDesc("sortByDate");
        //set the current page equal to 1
        this.currentPage = 1;
        this.loaded = true;
      },
      pageNumClick(pageNum) {
        this.currentPage = pageNum;
      },
      tryGetDateTimeString: tryGetDateTimeString,
    },
  };
</script>

<style scoped>
</style>
