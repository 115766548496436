<template>
  <div v-if="isAuthed">
    <div class="pt-1 bg-blue-darkest">
      <top-nav></top-nav>
    </div>
    <DashboardBanner :key="newAdminDashboardFeatureFlag" :flag="newAdminDashboardFeatureFlag" />
    <main class="main-content py-4 mt-4">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
  import TopNav from "./TopNav.vue"
  import DashboardBanner from "./DashboardBanner";
  import { scrollToAnchor } from "@/utils/scroll"
  import {ldInit, useLDFlag} from "launchdarkly-vue-client-sdk";

  export default {
  name: "PageLayout",
  components: {
    TopNav,
    DashboardBanner,
  },
  data() {
    return {
      // Don't display any portion of the app if we are not autheticated. It is janky otherwise.
      isAuthed: this.$auth.isAuthenticated,
      newAdminDashboardFeatureFlag: false,
    }
  },
  mounted: function() {
    // This is only called when the SPA is initially loaded, which is a case that
    // router.scrollPosition doesn't cover.
    // It ensures that, if the url contains an anchor #, the page scrolls down to that hash.
    if (this.$route.hash) {
      setTimeout(() => scrollToAnchor(this.$route.hash), 1)
    }

    const ldContext = { kind:'user', key:this.$auth.user.value.email, name: this.$auth.user.value.sub };
    ldInit({context: ldContext});

    this.newAdminDashboardFeatureFlag = useLDFlag('new-admin-dashboard', false)  },
  methods: {
    scrollFix: function (hashbang) {
      location.hash = hashbang
    }
  }
}
</script>

<style scoped></style>
