<template>
  <div>
    <!-- loading spinner -->
    <div v-if="loading" class="text-center">
      <div class="inline-block">
        <loading-spinner />
      </div>
    </div>

    <div v-if="!loading && account">
      <column-group>
        <account-breadcrumbs :account="account" />
      </column-group>

      <form @submit.prevent="onSubmit">
        <card>
          <!-- header -->
          <template #header>
            <div class="flex">
              <manage-page-heading heading="Surge" :details="!product.enabled ? 'Enable this product to modify features' : ''"></manage-page-heading>

              <div class="self-end">
                <btn v-if="product.enabled" @click="onDisableProductClick">Disable</btn>
                <btn v-if="!product.enabled" @click="onEnableProductClick">Enable</btn>
              </div>
            </div>
          </template>

          <!-- fields -->
          <div :class="[`${!product.enabled ? 'text-gray' : ''}`]">
            <validated-field-group label="Service Period">
              <template #details>
                <p>Start and end date</p>
              </template>
              <div class="flex justify-between items-center">
                <date-picker v-model="product.startDate" name="surge.startDate" :disabled="!product.enabled" />
                <p class="px-1.5">to</p>
                <date-picker v-model="product.endDate" name="surge.endDate" :disabled="!product.enabled" />
              </div>

              <template #validation-message>
                <FieldErrorMessage :field="v$.product.startDate" />
              </template>
            </validated-field-group>
            <validated-field-group label="Max Topics">
              <text-field v-model="product.maxTopics" name="surge.maxTopics" placeholder="0" :disabled="!product.enabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.maxTopics" />
              </template>
            </validated-field-group>
            <validated-field-group label="Max Clusters">
              <text-field v-model="product.maxClusters" name="surge.maxClusters" placeholder="0" :disabled="!product.enabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.maxClusters" />
              </template>
            </validated-field-group>
            <validated-field-group label="Max Companies">
              <text-field v-model="product.maxCompanies" name="surge.maxCompanies" placeholder="0" :disabled="!product.enabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.maxCompanies" />
              </template>
            </validated-field-group>
            <validated-field-group label="Max Net New Companies">
              <text-field v-model="product.maxNetNewCompanies" name="surge.maxNetNewCompanies" placeholder="0" :disabled="!product.enabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.maxNetNewCompanies" />
              </template>
            </validated-field-group>
            <validated-field-group label="Downloads">
              <enable-status-toggle :disabled="!product.enabled" v-model="product.downloadDisabled" name="surge.downloadDisabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.downloadDisabled" />
              </template>
            </validated-field-group>

            <separator></separator>

            <validated-field-group label="LinkedIn">
              <enable-status-toggle :disabled="!product.enabled" v-model="product.linkedInEnabled" name="surge.linkedInEnabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.linkedInEnabled" />
              </template>
            </validated-field-group>
            <validated-field-group label="Marketo">
              <enable-status-toggle :disabled="!product.enabled" v-model="product.marketoEnabled" name="surge.marketoEnabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.marketoEnabled" />
              </template>
            </validated-field-group>
            <validated-field-group label="Eloqua">
              <enable-status-toggle :disabled="!product.enabled" v-model="product.eloquaEnabled" name="surge.eloquaEnabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.eloquaEnabled" />
              </template>
            </validated-field-group>
            <validated-field-group label="Salesforce">
              <enable-status-toggle :disabled="!product.enabled" v-model="product.salesforceEnabled" name="surge.salesforceEnabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.salesforceEnabled" />
              </template>
            </validated-field-group>
            <validated-field-group label="Outreach">
              <enable-status-toggle :disabled="!product.enabled" v-model="product.outreachEnabled" name="surge.outreachEnabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.outreachEnabled" />
              </template>
            </validated-field-group>
            <validated-field-group label="Outreach Freemium">
              <enable-status-toggle :disabled="!product.enabled" v-model="product.outreachFreemiumEnabled" name="surge.outreachFreemiumEnabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.outreachFreemiumEnabled" />
              </template>
            </validated-field-group>
            <validated-field-group label="Salesforce Signal UI">
              <enable-status-toggle :disabled="!product.enabled" v-model="product.enhancedIntegrationUiEnabled" name="surge.enhancedIntegrationUiEnabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.enhancedIntegrationUiEnabled" />
              </template>
            </validated-field-group>

            <separator></separator>

            <validated-field-group label="HubSpot">
              <enable-status-toggle :disabled="!product.enabled" v-model="product.hubSpotEnabled" name="surge.hubSpotEnabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.hubSpotEnabled" />
              </template>
            </validated-field-group>
            <validated-field-group label="HubSpot Net New">
              <enable-status-toggle :disabled="!product.enabled" v-model="product.hubSpotNetNewEnabled" name="surge.hubSpotNetNewEnabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.hubSpotNetNewEnabled" />
              </template>
            </validated-field-group>
            <validated-field-group label="HubSpot Net New Cadence">
              <dropdown v-model="hubspotNetNewResetSchedule"
                        :items="hubspotNetNewScheduleValues"
                        name="surge.hubspotNetNewResetSchedule"
                        :disabled="!product.enabled || !product.hubSpotNetNewEnabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.hubspotNetNewResetSchedule" />
              </template>
            </validated-field-group>
            <validated-field-group label="HubSpot Net New Frequency" v-if="product.hubspotNetNewResetSchedule">
              <text-field v-model="hubspotNetNewResetFrequency" name="surge.hubspotNetNewResetFrequency" placeholder="0" :disabled="!product.enabled" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.hubspotNetNewResetFrequency" />
              </template>
            </validated-field-group>
            <validated-field-group label="HubSpot Net New Start Date" v-if="product.hubspotNetNewResetSchedule">
              <text-field v-model="product.hubspotNetNewResetStartDate" name="surge.hubspotNetNewResetStartDate" :disabled="true" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.product.hubspotNetNewResetStartDate" />
              </template>
            </validated-field-group>
          </div>
          <template v-slot:footer>
            <btn type="submit" color="orange" :is-disabled="!product.enabled || saving">
              {{saving ? "Saving..." : "Save"}}
            </btn>
            <Transition>
              <alert v-if="saveSuccess" style-type="success" class="mt-4">
                Your changes have been saved.
              </alert>
            </Transition>
            <Transition>
              <alert v-if="saveFailed" style-type="warning" class="mt-4">
                There was a problem saving your changes. Please try again later.
              </alert>
            </Transition>
            <Transition>
              <alert v-if="formInvalid" style-type="warning" class="mt-4">
                Please correct the errors in the form and resubmit.
              </alert>
            </Transition>
          </template>
        </card>
      </form>
    </div>

    <Transition>
      <product-disable-confirm-modal :show="showConfirmModal" product-name="Surge" @cancel="modalCancelHandler" @confirm="modalConfirmHandler" />
    </Transition>
  </div>
</template>

<script>
  import { Alert, Btn, Card, ColumnGroup, Dropdown, DatePicker, Separator, TextField } from "@bombora/component-library";

  import AccountBreadcrumbs from "@/components/shared/AccountBreadcrumbs";
  import EnableStatusToggle from "@/components/shared/EnableStatusToggle";
  import FieldErrorMessage from "@/components/shared/FieldErrorMessage";
  import ManagePageHeading from "@/components/shared/ManagePageHeading";
  import ProductDisableConfirmModal from "@/components/shared/ProductDisableConfirmModal";
  import ValidatedFieldGroup from "@/components/shared/ValidatedFieldGroup";

  import { useVuelidate } from '@vuelidate/core'
  import { minValue, helpers } from '@vuelidate/validators'

  const productName = 'surge';

  export default {
    name: "AccountDetailsProductSurge",
    components: {
      AccountBreadcrumbs,
      Alert,
      Btn,
      Card,
      ColumnGroup,
      Dropdown,
      DatePicker,
      EnableStatusToggle,
      FieldErrorMessage,
      ManagePageHeading,
      ProductDisableConfirmModal,
      Separator,
      TextField,
      ValidatedFieldGroup,
    },
    setup: () => ({ v$: useVuelidate() }),
    props: ['account', 'loading'],
    data() {
      return {
        product: {
          hubspotNetNewResetStartDate: null,
          hubspotNetNewResetFrequency: null,
          hubspotNetNewResetSchedule: null,
        },
        hubspotNetNewScheduleValues: [
          { value: '', label: 'Never' },
          { value: '1', label: 'Weeks' },
          { value: '2', label: 'Months' }
        ],
        showSaveModal: false,
        showConfirmModal: false,
        saving: false,
        saveSuccess: false,
        saveFailed: false,
        formInvalid: false,
      };
    },
    validations() {
      return {
        product: {
          startDate: {
            maxValue: helpers.withMessage('Start Date must be before End Date', value => {
              // Make it nullable
              if (!value || !this.product.endDate) return true;
              return new Date(value) < new Date(this.product.endDate)
            }),
          },
          maxTopics: {
            minValue: helpers.withMessage('Max Topics must be greater than or equal to zero', minValue(-1)),
          },
          maxClusters: {
            minValue: helpers.withMessage('Max Clusters must be greater than or equal to zero', minValue(-1)),
          },
          maxCompanies: {
            minValue: helpers.withMessage('Max Companies must be greater than or equal to zero', minValue(-1)),
          },
          maxNetNewCompanies: {
            minValue: helpers.withMessage('Max Net New Companies must be greater than or equal to zero', minValue(-1)),
          }
        }
      }
    },
    watch: {
      account: {
        handler: "getDataFromAccount",
        immediate: true
      }
    },
    computed: {
      // Calculate the new start date for the Hubspot cadence on any change to schedule or frequency
      hubspotNetNewResetSchedule: {
        // getter
        get() {
          return this.product.hubspotNetNewResetSchedule;
        },
        // setter
        set(newValue) {
          this.product.hubspotNetNewResetSchedule = newValue;

          if (!this.product.hubspotNetNewResetSchedule || this.product.hubspotNetNewResetSchedule == '') {
            this.product.hubspotNetNewResetFrequency = 0;
            this.product.hubspotNetNewResetStartDate = null;
          } else if (this.product.hubspotNetNewResetFrequency == 0) {
            // Set the frequency to 1 if the user set it to 0 but did specify a schedule
            this.product.hubspotNetNewResetFrequency = 1;
          }
          this.getNetNewNextCadenceDate();
        }
      },
      hubspotNetNewResetFrequency: {
        get() {
          return this.product.hubspotNetNewResetFrequency;
        },
        set(newValue) {
          this.product.hubspotNetNewResetFrequency = newValue;
          this.getNetNewNextCadenceDate();
        }
      }
    },
    mounted() {
      this.getDataFromAccount()
    },
    methods: {
      onDisableProductClick: function () {
        this.saveSuccess = false;
        if (this.product.enabled) {
          // If the product is currently active, show the modal for disable
          this.showConfirmModal = true;
        } else {
          this.product.enabled = !this.product.enabled;
        }
      },
      modalCancelHandler: function () {
        this.showConfirmModal = false;
      },
      modalConfirmHandler: function () {
        this.product.enabled = !this.product.enabled;
        this.showConfirmModal = false;
      },
      onEnableProductClick() {
        this.product.enabled = true;
      },
      onToggleClick: function (key, value) {
        this.product[key] = value;
      },
      async onSubmit() {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          console.debug(this.v$.$errors);
          this.formInvalid = true;
          setTimeout(() => this.formInvalid = false, 5000)
          return
        }
        else {
          this.saving = true
          this.$api.updateSurgeProduct(this.account.id, this.product)
            .then((res) => {
              this.saving = false
              this.saveSuccess = true
              setTimeout(() => this.saveSuccess = false, 5000)
            })
            .catch((err) => {
              this.saving = false
              this.saveFailed = true;
              setTimeout(() => this.saveFailed = false, 5000)
              console.error(err.message)
            })
        }
      },
      getDataFromAccount() {
        if (this.account && this.account.products) {
          this.product = { ...this.product, ...(this.account.products.find(p => p.id === productName) || {}) }
          this.initialValues = this.account.products.find(p => p.id === productName) || {}
        }
      },
      getNetNewNextCadenceDate() {
        if (
          (!this.product.hubspotNetNewResetFrequency || this.product.hubspotNetNewResetFrequency == '') ||
          (!this.product.hubspotNetNewResetSchedule || this.product.hubspotNetNewResetSchedule == ''))
          return null;
        debugger;
        var date = (!this.product.hubspotNetNewResetStartDate || this.product.hubspotNetNewResetStartDate == '') ? new Date() : new Date(this.product.hubspotNetNewResetStartDate);

        switch (this.product.hubspotNetNewResetSchedule) {
          case '1':
            date = this.addWeeks(date, this.product.hubspotNetNewResetFrequency);
            break;
          case '2':
            date = this.addMonths(date, this.product.hubspotNetNewResetFrequency);
            break;
        }

        this.product.hubspotNetNewResetStartDate = date.toLocaleDateString();
      },
      addMonths(dateObj, num) {
        return new Date(dateObj.setMonth(dateObj.getMonth() + num));
      },
      addWeeks(dateObj, num) {
        return new Date(dateObj.setHours(dateObj.getHours() + (num * 168)));
      }
    },
  };
</script>

<style scoped></style>
