export function findInsensitive(object, key) {
  // Running the tolowercase here will prevent the find loop from executing this code for every key.
  let lowerKey = key.toLowerCase();
  return object[
    Object.keys(object).find((key) => key.toLowerCase() === lowerKey)
  ];
}

//export extractData so that you can import it to the action file and use it for .then
export const extractData = (response) => {
    if (response.statusCode !== 200) {//throw an exception if the request failed or not 200
        throw new Error(response.message)
    }
    return response.data
};

export function debounce(func, wait, immediate) {
    let timeout
    return function (...args) {
        return new Promise((resolve) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                timeout = null
                if (!immediate) {
                    Promise.resolve(func.apply(this, [...args])).then(resolve)
                }
            }, wait)
            if (immediate && !timeout) {
                Promise.resolve(func.apply(this, [...args])).then(resolve)
            }
        })
    }
}

export function tryGetDateString(possibleDate) {
    let val = Date.parse(possibleDate)
    if (isNaN(val)) return "";
    let dt = new Date(val);
    return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear().toString().substring(2, 4)}`;
}
export function tryGetDateTimeString(possibleDate, isUtc) {
    let val = Date.parse(possibleDate)
    if (isNaN(val)) return "";
    // If this is UTC, append 'Z' to the date string
    let dt = new Date(isUtc ? `${possibleDate}Z` : possibleDate);
    return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear().toString().substring(2, 4)} ${dt.toLocaleTimeString() }`;
}

export function formatStringDate(date) {
    return new Date(date).toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" })
}


export function apiErrorHandler(error) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(`Response was ${error.response.status}`);
        console.error(error.response.data);
    } else if (error.request) {
        // The request was made but no response was received
        console.error('Request received no response');
        console.error(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Unexpected error when sending request', error.message);
    }
    console.debug(error.config);
}

export function unwrapServerResponse(response, customMessage = '') {
    if (response.status >= 200 && response.status < 300) { // 2xx status codes
        return {
            success: true,
            response: response
        }
    } else { // Any other status code
        let responseMessage = response.message ?? response.data?.message
        return {
            success: false,
            response: response,
            message: customMessage ? `${customMessage} ${responseMessage}` : responseMessage
        }
    }
}

// Converts a C# dict to an object list that works with select lists.
export function convertDictToList(dict) {
    return Object.entries(dict).map(([k, v]) => ({ value: k, label: v }));
}
