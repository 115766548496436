<template>
  <div class="main-content">
    <div class="flex">
      <div class="flex-1">
        <card>
          <template v-slot:header>
            <column-group :cols="3">
              <div>
                <h3 class="text-3xl font-bold">Accounts</h3>
              </div>
              <div class="justify-self-end inline-block col-span-2">
                <Btn v-if="hasReadUserPermission" :class="{ disabled: userFileDownloading }" :disabled="userFileDownloading" @click="usersReportDownload">
                  <spinner-icon v-if="userFileDownloading" width="16" height="16"></spinner-icon> User Report
                </Btn>
                <Btn v-if="hasReadAccountPermission" :class="{ disabled: accountFileDownloading }" :disabled="accountFileDownloading" @click="accountReportDownload">
                  <spinner-icon v-if="accountFileDownloading" width="16" height="16"></spinner-icon> Account Report
                </Btn>
                <Btn color="orange" @click="goToEmptyAccountInfo" :is-disabled="addAccountButtonDisabled">
                  Add a New Account
                </Btn>
              </div>
            </column-group>
          </template>
          <column-group class="pb-4">
            <text-field placeholder="Search by account name or account ID"
                        name="searchNameOrId"
                        :fullWidth="true"
                        class="mr-3"
                        :modelValue="listParams.search"
                        @update:modelValue="onSearch">
              <template #suffix>
                <icon iconType="spyglass" class="w-4" />
              </template>
            </text-field>
            <column-group>
              <dropdown placeholder="Any Product Enabled"
                        name="filterProductEnabled"
                        :fullWidth="true"
                        :items="products"
                        v-model="listParams.productEnabled"
                        class="mr-3" />
              <dropdown placeholder="Filter by entity type"
                        name="filterEntityType"
                        :fullWidth="true"
                        :items="entityTypes"
                        v-model="listParams.accountType" />
            </column-group>
          </column-group>
          <grid v-if="loaded && rows.length > 0">
            <template #gridHeaderCells>
              <grid-header-cell column-key="Expand" :sortable="false"></grid-header-cell>
              <grid-header-cell @table-sorted="() => sort('sortByName')">Name</grid-header-cell>
              <grid-header-cell @table-sorted="() => sort('sortByAccountId')">Account ID</grid-header-cell>
              <grid-header-cell column-key="entityType" :sortable="false">Entity Type</grid-header-cell>
              <grid-header-cell column-key="salesforceAccountId" :sortable="false">Salesforce Account ID</grid-header-cell>
              <grid-header-cell column-key="expiring" :sortable="false">Surge Expiring</grid-header-cell>
              <grid-header-cell column-key="Details" :sortable="false"></grid-header-cell>
            </template>
            <template #rows>
              <template v-for="(dataRow, index) in rows" :key="dataRow.id">
                <grid-row>
                  <grid-cell class="cursor-pointer">
                    <button class="w-full" @click="cellClicked(index)" aria-label="Expand">
                      <Icon class="h-3"
                            :iconType="dataRow.detailOpen ? 'pointerup' : 'pointerdown'" />
                    </button>
                  </grid-cell>
                  <grid-cell>{{ dataRow.name }}</grid-cell>
                  <grid-cell>{{ dataRow.id }}</grid-cell>
                  <grid-cell>{{ dataRow.type }}</grid-cell>
                  <grid-cell>{{ dataRow.salesforceAccountId }}</grid-cell>
                  <grid-cell>{{ tryGetDateString(findProduct(dataRow, "surge")?.endDate) }}</grid-cell>
                  <grid-cell>
                    <router-link :to="{ name: 'AccountInfo', params: { id: `${dataRow.id}` }}">
                      <btn size="small">Edit</btn>
                    </router-link>
                  </grid-cell>
                </grid-row>
                <grid-row v-if="dataRow.detailOpen">
                  <grid-cell :index="index" :col-span="7">
                    <table class="w-full table-auto">
                      <tr class="text-left">
                        <th>DAB</th>
                        <th>Surge</th>
                        <th>Audience Verification</th>
                        <th>Visitor Insights</th>
                        <th>VI Feed</th>
                      </tr>
                      <tr>
                        <td>{{ getProductDateRange(dataRow, "dsb") }}</td>
                        <td>{{ getProductDateRange(dataRow, "dsb") }}</td>
                        <td>{{ getProductDateRange(dataRow, "surge") }}</td>
                        <td>{{ getProductDateRange(dataRow, "aud_verification") }}</td>
                        <td>{{ getProductDateRange(dataRow, "firehose") }}</td>
                      </tr>
                    </table>
                  </grid-cell>
                </grid-row>
              </template>
            </template>
          </grid>
          <div v-if="!loaded" class="text-center">
            <div class="inline-block">
              <loading-spinner />
            </div>
          </div>
          <!-- display this message if there's no row data -->
          <div v-if="loaded && rows.length == 0" class="text-blue-darkest font-semibold text-center">There are no Accounts to display.</div>
          <template #footer>
            <grid-pager :page-size="10"
                        :total-pages="totalPages"
                        :current-page="currentPage"
                        @paged="pageNumClick"></grid-pager>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Btn,
    Card,
    ColumnGroup,
    Dropdown,
    Grid,
    GridCell,
    GridHeaderCell,
    GridPager,
    GridRow,
    Icon,
    LoadingSpinner,
    SpinnerIcon,
    TextField,
  } from "@bombora/component-library";

  import {convertDictToList, debounce, tryGetDateString} from "../../../helpers.js";

  export default {
    name: "AccountList",
    components: {
      Btn,
      Card,
      ColumnGroup,
      Dropdown,
      Grid,
      GridCell,
      GridHeaderCell,
      GridPager,
      GridRow,
      Icon,
      LoadingSpinner,
      SpinnerIcon,
      TextField,
    },
    props: {},
    data: function () {
      return {
        rows: [],
        loaded: false,
        nameFilter: "",
        productFilter: "",
        entityFilter: "",
        entityTypes: [],
        products: [
          {
            label: "Audience Verification",
            value: "aud_verification",
          },
          {
            label: "DAB",
            value: "dsb",
          },
          {
            label: "Surge",
            value: "surge",
          },
          {
            label: "VI Feed",
            value: "firehose",
          },
          {
            label: "Visitor Insights",
            value: "visitor_insights",
          },
        ],
        listParams: {
          search: "",
          page: 1,
          pageSize: 10,
          productEnabled: "",     // Filter for accounts with this product enabled. Default none (all accounts)
          accountType: "",        // Filter for accounts of this type
          sortByName: "asc",      // Will be set to "asc" or "desc" if user sorts by column
          sortByAccountId: null   // Will be set to "asc" or "desc" if user sorts by column
        },
        sortProperties: [         // The property names to set on listParams that are sent as query parameters to the backend
          "sortByName",
          "sortByAccountId"
        ],
        totalPages: 0,
        userFileDownloading: false,
        accountFileDownloading: false,
        addAccountButtonDisabled: false,
        hasReadUserPermission: false,
        hasReadAccountPermission: false
      };
    },
    mounted() {
      this.refreshList();
      this.updateAddAccountButton();
      this.checkReadAccountPermission();
      this.checkReadUsersPermission();
      this.$api.getAccountTypes()
        .then((res) => {
          this.entityTypes = convertDictToList(res.data);
        })
    },
    watch: {
      listParams: {
        handler: debounce(function () {
          this.refreshList()
        }, 500),
        deep: true // Watch properties on this object
      }
    },
    methods: {
      onSearch(value) {
        // Set the search value to trigger the watch.
        this.listParams.search = value;
        this.listParams.page = 1;
      },
      updateAddAccountButton() {
        this.$auth.userHasPermission("create:account").then(data => {
          this.addAccountButtonDisabled = !data;
        });
      },
      checkReadUsersPermission() {
        this.$auth.userHasPermission("read:users")
          .then(allowed => { this.hasReadUserPermission = allowed });
      },
      checkReadAccountPermission() {
        this.$auth.userHasPermission("read:account")
          .then(allowed => { this.hasReadAccountPermission = allowed });
      },
      goToEmptyAccountInfo() {
        this.$router.push({ name: 'AccountInfo', params: { id: 0 } })
      },
      sort(sortProperty) {
        this.sortProperties.forEach(prop => {
          if (prop === sortProperty)
            this.listParams[prop] = this.listParams[prop] === "asc" ? "desc" : "asc";
          else
            this.listParams[prop] = null;
        });
      },
      refreshList() {
        this.loaded = false;
        // Add the watched search object to list params here
        this.$api.getAccounts(this.listParams)
          .then((res) => {
            this.rows = res.data.accounts || [];
            this.totalPages = res.data.totalPages || 0;
            this.totalAccounts = res.data.totalAccounts || 0;
            this.loaded = true;
          })
          .catch((err) => console.error(err.message))
      },
      cellClicked(rowIndex) {
        if (this.rows[rowIndex].detailOpen == undefined) {
          this.rows[rowIndex].detailOpen = true;
        } else {
          this.rows[rowIndex].detailOpen =
            !this.rows[rowIndex].detailOpen;
        }
        // Close all other detail rows.
        this.rows.forEach((e, i) => {
          if (e.detailOpen != undefined && i != rowIndex) e.detailOpen = false;
        });
      },
      pageNumClick(pageNum) {
        this.listParams.page = pageNum;
      },
      findProduct(accountRow, id) {
        // returns undefined on no product since filter will return undefined if it's condition is false.
        if (accountRow.products) {
          return accountRow.products.find(p => p.id === id)
        }
        return undefined;
      },
      tryGetDateString: tryGetDateString,
      getProductDateRange(row, productId) {
        let accountProduct = this.findProduct(row, productId);
        if (accountProduct != undefined) {
          let dateString = `${this.tryGetDateString(accountProduct.startDate)} - ${this.tryGetDateString(accountProduct.endDate)}`;
          // For consistent UI, remove the empty separator value if both dates are empty.
          return dateString == ' - ' ? '' : dateString;
        }
        return "not set";
      },
      usersReportDownload() {
        this.userFileDownloading = true;
        this.$api.getUserReportFile()
          .then(() => {
            this.userFileDownloading = false;
          })
          .catch((error) => {
            console.error(error);
            this.userFileDownloading = false
          })

      },
      accountReportDownload() {
        this.accountFileDownloading = true;
        this.$api.getAccountReportFile()
          .then(() => {
            this.accountFileDownloading = false;
          })
          .catch((error) => {
            console.error(error);
            this.accountFileDownloading = false
          })
      }
    },
    computed: {
      currentPage() {
        return this.listParams.page
      }
    }
  };
</script>

<style scoped></style>
