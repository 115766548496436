<template>
  <div class="px-5 pb-4">
    <div class="inline-block px-1">
      {{ breadcrumb }}
    </div>
  </div>
</template>

<script>
  import Breadcrumbs from "@bombora/component-library/dist/Breadcrumbs";

  export default {
    name: "AccountBreadcrumbs",
    components: { Breadcrumbs },
    props: ['account'],
    computed: {
      breadcrumb() {
        let breadcrumb = this.account.name ? `Manage Account ${this.account.name} (ID: ${ this.account.id })` : "Add New Account";
        return breadcrumb;
      }
    }
  };
</script>
