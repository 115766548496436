<template>
  <div class="flex-1">
    <h3 class="text-2xl pb-1" :aria-describedby="heading">
      {{heading}}
    </h3>
    <p v-if="details != ''" class="font-light text-gray-light">
      {{details}}
    </p>
  </div>
</template>

<script>
  // The main purpose of this component is to encapsulate ARIA information so the developer
  // doesn't have to think about it.
  export default {
    name: "ManagePageHeading",
    props: {
      heading: {
        type: String
      },
      details: {
        type: String
      }
    }
  };
</script>
