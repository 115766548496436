<template>
  <div v-if="newAdminDashboardFeatureFlag" class="bg-blue-darkest p-4 text-center text-white text-sm">
    <icon iconType="alert" iconColor="" class="h-5 pb-1" />
    <a href="https://surge.bombora.com/Admin" class="hover:underline">Click here to visit the older version of the admin dashboard.</a>
  </div>
</template>

<script setup>

</script>

<script>
import Icon from "@bombora/component-library/dist/Icon";

export default {
  name: "DashboardBanner",
  components: {
    Icon
  },
  props: {
    flag: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      // Don't display any portion of the app if we are not autheticated. It is janky otherwise.
      newAdminDashboardFeatureFlag : false
    }
  },
  mounted() {
    this.newAdminDashboardFeatureFlag = this.flag;
  }
}
</script>

<style scoped>
</style>
