<template>
  <div v-if="text.length > limit">
    <Tooltip>
      <span>{{ shortenText(text, textLength) }}</span>
      <template #popper>
        {{ text }}
      </template>
    </Tooltip>
  </div>
  <div v-else>
    {{ text }}
  </div>
</template>

<script>
  import Tooltip from "@/components/shared/Tooltip.vue";

  export default {
    name: "EllipsisTextBlock",
    components: {
      Tooltip
    },
    props: {
      text: String,
      length: Number
    },
    data() {
      return {
        limit: this.length + 2,
        textLength: this.length
      }
    },
    methods: {
      shortenText(text, lastCharIdx) {
        return text.slice(0, lastCharIdx) + "..."
      },
    },
  }
</script>
