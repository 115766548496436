export default api => {
    const uri = `/release`;

    return {
        getRelease: async () => {
            return (await api.get({url: `${uri}`}).then((res) => res))
        },
        submitRelease: async (formData) => {
            return (await api.postForm({ url: `${uri}`, data: formData }).then((res) => res))
        }
    }
}
