<template>
  <div class="bg-cover bg-no-repeat fixed top-0 left-0 min-h-full h-auto w-full" style="background-image: url('/img/bg.png');">
    <div class="main-content text-center text-white mt-20">
      <h1 class="mt-6 mb-4 text-2xl font-semibold">
        Looks like you've swum into deep waters!
      </h1>
      <p>
        This part of the ocean is for authorized users only.
        Please contact your adminstrator to gain access to this Bombora feature.
      </p>

      <btn color="orange" class="mt-12" @click="goBack">Take me back</btn>
    </div>
  </div>
</template>

<script>
  import { Btn } from "@bombora/component-library";

  export default {
    name: "Unauthorized",
    components: {
      Btn
    },
    methods: {
      goBack() {
        window.location.href = "https://bombora.com";
      }
    }
  }
</script>
