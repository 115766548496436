<script>
import {PopperWrapper} from 'floating-vue'

export default {
  ...PopperWrapper,
  name: 'Tooltip',
  vPopperTheme: 'bombora-tooltip',
}
</script>

<style>
.v-popper--theme-bombora-tooltip .v-popper__inner {
  @apply bg-blue-dark text-white py-1 px-1.5 rounded text-sm
}

.v-popper--theme-bombora-tooltip .v-popper__arrow-outer {
  @apply border-blue-dark
}
</style>
