<template>
  <div class="main-content">
    <div class="flex">
      <div class="flex-1">
        <form v-if="loaded" @submit.prevent="">
          <card>
            <template v-slot:header>
              <column-group :cols="3">
                <div>
                  <h3 class="text-2xl font-bold">Topic Interest Historical Analysis</h3>
                </div>
              </column-group>
            </template>
            <!-- fields -->
            <column-group>
              <div class="mr-12">
                <div class="mb-4">
                  <validated-vert-field-group label="Report Name" required>
                    <text-field v-model="tiha.reportName" name="tiha.reportName" />
                    <template #validation-message>
                      <FieldErrorMessage :field="v$.tiha.reportName" />
                    </template>
                  </validated-vert-field-group>
                </div>
                <div class="mb-4">
                  <validated-vert-field-group label="Account" required>
                    <dropdown :items="accounts" v-model="tiha.account" name="tiha.account" />
                    <template #validation-message>
                      <FieldErrorMessage :field="v$.tiha.account" />
                    </template>
                  </validated-vert-field-group>
                </div>
                <div class="mb-6">
                  <validated-vert-field-group label="Domain File to Validate" required>
                    <div class="h-20">
                      <file-drop-zone data-id="domain-upload-action" @updated="onDomainFileUploaded" :accept="'.csv'">
                        <div class="w-full h-full grid place-content-center">
                          <div class="w-8 h-8 place-self-center">
                            <icon icon-type="upload" class="w-6 h-6"></icon>
                          </div>
                          <p class="text-dark-gray">Click to upload or drag and drop your CSV files</p>
                        </div>
                      </file-drop-zone>
                    </div>
                    <p class="text-sm text-gray italic">Your domain CSV file must have headers, a row for <em>domain</em> and <em>opp_date</em> are required, and <em>opp_date</em> cannot be a future date.</p>
                    <div v-if="domainFileName !==  null">
                      <div class="p-2 border-dark-gray bg-gray-lightest mb-5 rounded">
                        {{domainFileName}}
                      </div>
                    </div>
                    <template #validation-message>
                      <p class="text-sm text-red" v-if="showDomainFileErrorMessage">
                        A Domain file is required.
                      </p>
                    </template>
                  </validated-vert-field-group>
                </div>
                <div class="mb-6">
                  <validated-vert-field-group label="Topic File to Validate" required>
                    <div class="h-20">
                      <file-drop-zone data-id="topic-upload-action" @updated="onTopicFileUploaded" :accept="'.csv'">
                        <div class="w-full h-full grid place-content-center">
                          <div class="w-8 h-8 place-self-center">
                            <icon icon-type="upload" class="w-6 h-6"></icon>
                          </div>
                          <p class="text-dark-gray">Click to upload or drag and drop your CSV files</p>
                        </div>
                      </file-drop-zone>
                    </div>
                    <p class="text-sm text-gray italic">Your topic CSV file must have headers, a row for <em>topic_name</em> and <em>topic_id</em> are required.</p>
                    <div v-if="topicFileName !==  null">
                      <div class="p-2 border-dark-gray bg-gray-lightest mb-5 rounded">
                        {{topicFileName}}
                      </div>
                    </div>
                    <template #validation-message>
                      <p class="text-sm text-red" v-if="showTopicFileErrorMessage">
                        A Topic file is required.
                      </p>
                    </template>
                  </validated-vert-field-group>
                </div>
                <div class="mb-4">
                  <validated-field-group :wide-label="true" label="Has customer provided consent for Bombora to use this data for alternative purposes?" required>
                    <toggle :checked="false" v-model="tiha.alternativePurposes" name="tiha.alternativePurposes"></toggle>
                    <template #validation-message>
                      <FieldErrorMessage :field="v$.tiha.alternativePurposes" />
                    </template>
                  </validated-field-group>
                </div>
              </div>
              <div>
                <h4 class="text-xl font-bold mb-6">TIHA Job Options</h4>
                <validated-field-group label="Lookback Window" required>
                  <dropdown :items="lookbackDropdownOptions" v-model="tiha.lookbackWindow" name="tiha.lookbackWindow" />
                  <template #validation-message>
                    <FieldErrorMessage :field="v$.tiha.lookbackWindow" />
                  </template>
                </validated-field-group>

                <btn @click="onTIHAJob" color="orange" :disabled="isRunning"><spinner-icon v-if="isRunning" width="16" height="16" class="mr-2" />Run TIHA Job</btn>

                <separator />
                <h4 class="text-xl font-bold mb-6">Control Job Options</h4>
                <validated-field-group label="Company Surge® Score Threshold" required>
                  <number-field v-model="tiha.surgeScore" name="tiha.surgeScore" />
                  <template #validation-message>
                    <FieldErrorMessage :field="v$.tiha.surgeScore" />
                  </template>
                </validated-field-group>
                <validated-field-group label="Precision Type" required>
                  <dropdown :items="precisionDropdownOptions" v-model="tiha.precision" name="tiha.precision" />
                  <template #validation-message>
                    <FieldErrorMessage :field="v$.tiha.precision" />
                  </template>
                </validated-field-group>

                <btn @click="onControlJob" color="orange" :disabled="isRunning"><spinner-icon v-if="isRunning" width="16" height="16" class="mr-2" />Run Control Group Job</btn>
              </div>
            </column-group>
            <Transition>
              <alert v-if="runSuccess" style-type="success" class="mt-4">
                The job has been scheduled successfully.
              </alert>
            </Transition>
            <Transition>
              <alert v-if="runFailed" style-type="warning" class="mt-4">
                {{errorMessage}}
              </alert>
            </Transition>
            <Transition>
              <alert v-if="formInvalid" style-type="warning" class="mt-4">
                Please correct the errors in the form and resubmit.
              </alert>
            </Transition>
          </card>
        </form>
        <div v-if="!loaded" class="text-center">
          <div class="inline-block">
            <loading-spinner />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Alert, Btn, Card, TextField, TextArea, Toggle, Dropdown, Modal, ColumnGroup, LoadingSpinner, SpinnerIcon, Separator } from "@bombora/component-library";
  import FieldErrorMessage from "@/components/shared/FieldErrorMessage";
  import ValidatedFieldGroup from "@/components/shared/ValidatedFieldGroup.vue";
  import ValidatedVertFieldGroup from "@/components/shared/ValidatedVertFieldGroup.vue";
  import FileDropZone from "@/components/shared/FileDropZone.vue";
  import NumberField from "@/components/shared/NumberField.vue";
  import Icon from "@/components/shared/Icon.vue";
  import { useVuelidate } from '@vuelidate/core'
  import { required, minLength, maxLength, minValue, maxValue, helpers } from '@vuelidate/validators'

  export default {
    name: "TIHA",
    components: {
      Alert,
      Btn,
      TextField,
      Toggle,
      Dropdown,
      Card,
      TextArea,
      NumberField,
      Icon,
      Modal,
      ValidatedFieldGroup,
      ValidatedVertFieldGroup,
      FileDropZone,
      FieldErrorMessage,
      LoadingSpinner,
      SpinnerIcon,
      ColumnGroup,
      Separator
    },
    setup: () => ({ v$: useVuelidate() }),
    data: function () {
      return {
        lookbackDropdownOptions: [
          { label: "30 Days", value: 30 },
          { label: "60 Days", value: 60 },
          { label: "90 Days", value: 90 },
          { label: "120 Days", value: 120 },
        ],
        precisionDropdownOptions: [
          { label: "High", value: 'high' },
          { label: "Low", value: 'low' },
        ],
        accounts: [{ label: "Loading...", value: 0 }],
        accountDropdownlistParams: {
          search: "",
          page: 1,
          pageSize: 1000,
          sortByName: "asc"
        },
        tiha: {
          reportName: '',
          account: 50067,
          lookbackWindow: 90,
          precision: 'high',
          surgeScore: 60,
          alternativePurposes: false
        },
        loaded: true,
        isRunning: false,
        runSuccess: false,
        runFailed: false,
        formInvalid: false,
        domainFileName: null,
        topicFileName: null,
        showTopicFileErrorMessage: false,
        showDomainFileErrorMessage: false,
        errorMessage: ''
      };
    },
    validations() {
      return {
        tiha: {
          reportName: {
            required: helpers.withMessage('Job Name is required.', required),
            minLength: helpers.withMessage('Job Name must be at least 3 characters.', minLength(3)),
            maxLength: helpers.withMessage('Job Name must less than 100 characters.', maxLength(100))
          },
          account: {
            required: helpers.withMessage('Account is required.', required),
          },
          surgeScore: {
            minValue: helpers.withMessage('Surge Score must be between 0 and 100.', minValue(0)),
            maxValue: helpers.withMessage('Surge Score must be between 0 and 100.', maxValue(100))
          }
        }
      }
    },
    mounted() {
      this.getAccounts();
    },
    methods: {
      getAccounts() {
        this.$api.getAccounts(this.accountDropdownlistParams)
          .then((res) => {
            this.accounts = res.data.accounts.map(x => { return { label: x.name, value: x.id } }) || [];
            this.loaded = true;
          })
          .catch((err) => console.error(err.message))
      },
      onDomainFileUploaded(files) {
        this.domainFileToUpload = files[0];
        this.domainFileName = this.domainFileToUpload.name
      },
      onTopicFileUploaded(files) {
        this.topicFileToUpload = files[0];
        this.topicFileName = this.topicFileToUpload.name
      },
      async onControlJob() {
        await this.onSubmit('control');
      },
      async onTIHAJob() {
        await this.onSubmit('tiha');
      },
      async onSubmit(jobType) {
        this.showTopicFileErrorMessage = this.topicFileName == null;
        this.showDomainFileErrorMessage = this.domainFileName == null;
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect || this.topicFileName == null || this.domainFileName == null) {
          this.formInvalid = true;
          setTimeout(() => this.formInvalid = false, 5000)
          return
        }
        else {
          this.isRunning = true;
          this.formData = new FormData();
          this.formData.append('topicFile', this.topicFileToUpload)
          this.formData.append('domainFile', this.domainFileToUpload)
          this.formData.append('reportName', this.tiha.reportName)
          this.formData.append('accountId', this.tiha.account)
          this.formData.append('userEmail', this.$auth.user.value.email)
          this.formData.append('alternativeUseConsent', this.tiha.alternativePurposes)
          if (jobType == "control") {
            // Add precision and surge score for control job
            this.formData.append('precision', this.tiha.precision)
            this.formData.append('surgeScore', this.tiha.surgeScore)

            this.$api.runControlJob(this.formData)
              .then((res) => {
                this.isRunning = false
                if (res.success) {
                  this.runSuccess = true
                  setTimeout(() => this.runSuccess = false, 5000)
                } else {
                  this.runFailed = true
                  this.errorMessage = res.message;
                  setTimeout(() => this.runFailed = false, 5000)
                }
              })
              .catch((err) => {
                this.isRunning = false
                this.runFailed = true;
                this.errorMessage = 'The Control Group job could not be queued. Please try again later.';
                setTimeout(() => this.runFailed = false, 5000)
                console.error(err.message)
              })
          }
          if (jobType == "tiha") {
            this.formData.append('reportPeriodLength', this.tiha.lookbackWindow)

            this.$api.runTIHAJob(this.formData)
              .then((res) => {
                this.isRunning = false
                if (res.success) {
                  this.runSuccess = true
                  setTimeout(() => this.runSuccess = false, 5000)
                } else {
                  this.runFailed = true
                  this.errorMessage = res.message;
                  setTimeout(() => this.runFailed = false, 5000)
                }
              })
              .catch((err) => {
                this.isRunning = false
                this.runFailed = true;
                this.errorMessage = 'The TIHA job could not be queued. Please try again later.';
                setTimeout(() => this.runFailed = false, 5000)
                console.error(err.message)
              })
          }
        }
      },
    }
  }
</script>

<style>
</style>
