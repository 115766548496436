/**
 * validFormattedDomainRegex is used to validate properly formatted URLS such has
 * - https://www.example.com
 * - http://subdomain.example.co.uk
 * - https://my-website.org/path/to/page
 * - http://www.example.com?param1=value&param2=value
 * - www.example.com
 * - example.com
 * **/

const validFormattedDomainRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}(\/\S*)?(\?\S*)?$/

/**
 * validCleanDomainRegex and cleanDomain should be used in conjunction with each other like so:
 *
 * // clean and validate it
 * let cleaned = cleanDomain(value)
 * return validCleanDomainRegex.test(cleaned);
 * **/

const validCleanDomainRegex = /^(?:[a-zA-Z\d][a-zA-Z\d-]{0,61}[a-zA-Z\d]\.|[a-zA-Z\d]\.)+[a-zA-Z][a-zA-Z\d-]{0,61}[a-zA-Z\d].*$/

const cleanDomain = function (domain) {
    let cleanDomain = domain
        .replace("https://", "")
        .replace("http://", "")
        .replace("http:", "")
        .replace("https:", "");

    if (cleanDomain.startsWith("www.")) {
        cleanDomain = cleanDomain.substring(4);
    }

    return cleanDomain;
}

export { validCleanDomainRegex, cleanDomain, validFormattedDomainRegex }
