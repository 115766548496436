<template>
  <modal @close="handleClose">
    <form @submit.prevent="onSubmit">
      <div class="px-5 mb-4">
        <!-- Modal Header-->
        <div class="mb-5">
          <h1 class="text-3xl flex font-bold">{{ isNew ? "New" : "Edit" }} User</h1>
          <p class="text-gray">{{ isNew ? "Create a new user for this account" : "Manage this user's account information" }}</p>
        </div>
        <!-- User Information Text Inputs -->
        <div class="mb-14">
          <p class="font-bold pb-2.5">User Information</p>
          <div class="grid grid-cols-2 gap-x-32 gap-y-4">
            <div class="flex flex-row">
              <div class="mt-1 float-left leading-8 min-width">
                <label for="given_name">First Name</label>
              </div>
              <div class="flex flex-col">
                <text-field v-model="editingUser.given_name" name="usermodal.given_name" ref="given_name" />
                <div class="h-5 text-right">
                  <FieldErrorMessage :field="v$.editingUser.given_name"></FieldErrorMessage>
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="mt-1 float-left leading-8 min-width">
                <label for="family_name">Last Name</label>
              </div>
              <div class="flex flex-col">
                <text-field v-model="editingUser.family_name" name="usermodal.family_name" />
                <div class="h-5 text-right">
                  <FieldErrorMessage :field="v$.editingUser.family_name"></FieldErrorMessage>
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="float-left leading-8 min-width">
                <label for="email">Email</label>
              </div>
              <div class="flex flex-col">
                <text-field v-model="editingUser.email" name="usermodal.email" />
                <div class="h-5 text-right">
                  <FieldErrorMessage :field="v$.editingUser.email"></FieldErrorMessage>
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="float-left leading-8 min-width">
                <label for="email">Blocked</label>
              </div>
              <div class="flex flex-col">
                <badge :color="editingUser.blocked ? 'red': 'green'" size="small">
                  {{editingUser.blocked ?? 'false'}}
                </badge>
              </div>
            </div>
          </div>
          <div class="w-full" v-if="!isNew">
            <div class="mt-4">
              <btn color="orange" @click="triggerPasswordReset">Trigger Password Reset</btn>
              <btn v-if="account.id != bomboraAccountId" color="blue-light-line" @click="triggerUserBlock" :disabled="blockCallInProgress">
                <spinner-icon v-if="blockCallInProgress" width="16" height="16"></spinner-icon> {{blockBtnText}}
               </btn>
            </div>
            <Transition>
              <alert v-if="resetSuccess" style-type="success" class="mt-4">
                A password reset email was sent to the user.
              </alert>
            </Transition>
          </div>
        </div>
        <column-group :cols="2">
          <div>
            <!-- Roles block -->
            <div class="mb-4">
              <p class="font-bold">Role</p>
              <p class="text-gray">Set access level</p>
              <FieldErrorMessage :field="v$.editingUser.uiRoleId"></FieldErrorMessage>
            </div>
            <div class="block mb-2" v-for="roleData in roles">
              <radio :value="roleData.id" v-model="editingUser.uiRoleId" :label="roleData.name" :description="roleData.description"></radio>
            </div>
          </div>
          <div>
            <!-- Permissions block -->
            <div class="mb-4">
              <p class="font-bold">Permissions</p>
              <p class="text-gray">Enable access</p>
            </div>
            <div class="mt-2 mb-6 grid permissions-columns">
              <div class="flex items-center text-gray leading-4 2xl:w-96">
                Company Surge® API Access
              </div>
              <div class="text-right">
                <div class="float-right">
                  <enable-status-toggle v-model="editingUser.apiUser" :show-status-text="false" />
                </div>
              </div>
            </div>
            <div class="mt-2 mb-6 grid permissions-columns">
              <div class="flex items-center text-gray leading-4 2xl:w-96">
                Market Insights Access
              </div>
              <div class="text-right">
                <div class="float-right">
                  <enable-status-toggle v-model="editingUser.marketInsightsViewer" :show-status-text="false" />
                </div>
              </div>
            </div>
          </div>
        </column-group>
        <div class="w-full left-0 bottom-0">
          <div class="my-2">
            <btn type="submit" color="orange" :is-disabled="saving">
              {{ saving ? "Saving..." : "Save"
                  }}
                  </btn>
                  <Transition>
                    <alert v-if="saveSuccess" style-type="success" class="mt-4">
                      The user was saved successfully.
                    </alert>
                  </Transition>
                  <Transition>
                    <alert v-if="saveFailed" style-type="warning" class="mt-4">
                      {{userErrorMessage}}
                    </alert>
                  </Transition>
</div>
        </div>
      </div>
    </form>

  </modal>
</template>

<script>
  import { Alert, Badge, Btn, ColumnGroup, Modal, TextField, SpinnerIcon, RadioListItem } from "@bombora/component-library";

  import EnableStatusToggle from "@/components/shared/EnableStatusToggle";
  import FieldErrorMessage from "@/components/shared/FieldErrorMessage";

  import { useVuelidate } from '@vuelidate/core'
  import { required, helpers } from '@vuelidate/validators'

  import { apiErrorHandler } from '@/helpers.js';
  let defaultMessage = 'There was a problem saving the user changes. Please try again later.';

  export default {
    name: "UserAddEditModal",
    components: {
      Alert,
      Badge,
      Modal,
      TextField,
      FieldErrorMessage,
      Btn,
      Radio: RadioListItem,
      ColumnGroup,
      EnableStatusToggle,
      SpinnerIcon,
    },
    emits: ['close'],
    setup: () => ({ v$: useVuelidate() }),
    props: ['account', 'user', 'roles', 'isNew'],
    data() {
      return {
        bomboraAccountId: 50067,
        initialValues: {
          given_name: '',
          family_name: '',
          email: '',
          uiRoleId: '',
          apiUser: false,
          marketInsightsViewer: false,
          blocked: false
        },
        editingUser: this.user ?? { },
        saveSuccess: false,
        saving: false,
        saveFailed: false,
        // Indicated whether the user list is now dirty because of updates.
        dirtyClose: false,
        // Indicates whether this was a user create, to pop the create message on the list page
        userCreated: false,
        userErrorMessage: defaultMessage,
        availablePermissions: [
          {
            label: "Company Surge® API Access",
            value: 'ApiUser',
          },
          {
            label: "Market Insights",
            value: 'MarketInsightsViewer',
          }
        ],
        permissions: [],
        resetSuccess: false,
        blockCallInProgress: false
      }
    },
    validations() {
      return {
        editingUser: {
          given_name: {
            required: helpers.withMessage('First Name is required', required),
          },
          family_name: {
            required: helpers.withMessage('Last Name is required', required),
          },
          email: {
            required: helpers.withMessage('Email is required', required),
          },
          uiRoleId: {
            required: helpers.withMessage('Selecting a Role is required', required),
          },
        }
      }
    },
    computed: {
      blockBtnText() {
        return this.editingUser.blocked ? "Unblock User" : "Block User"
      },
    },
    methods: {
      handleClose(event) {
        this.$emit("close", { dirtyClose: this.dirtyClose, userCreated: this.userCreated });
      },
      triggerPasswordReset() {
        this.$api.triggerPasswordReset(this.editingUser.email)
          .then(() => {
            this.resetSuccess = true;
            setTimeout(() => this.resetSuccess = false, 5000)
          })
          .catch((err) => apiErrorHandler(err))
      },
      triggerUserBlock() {
        this.blockCallInProgress = true;
        var currentBlockState = this.editingUser.blocked ?? false;
        var newBlockState = !currentBlockState;
        // This is a boolean flag on the user object, the api call will just toggle the flag
        this.$api.toggleUserBlock(this.editingUser.user_id, { blocked: newBlockState })
          .then((res) => {
            this.editingUser.blocked = res.data.user.blocked;
            this.blockCallInProgress = false;
            this.dirtyClose = true;
          })
          .catch((err) => {
            console.error(err.message);
            this.blockCallInProgress = false;
            // We currently do not have error messages that can float. (toasts) THIS DOES NOT DISPLAY ANYTHING
            this.showUserBlockError = true;
            setTimeout(() => this.showUserBlockError = false, 7000);
          });
      },
      async onSubmit() {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          console.debug(this.v$.$errors);
          return
        }
        else {
          this.saving = true;
          if (!this.isNew) {
            // This is an update
            this.$api.updateUser(this.editingUser.user_id, this.editingUser)
              .then((res) => {
                this.saving = false
                this.saveSuccess = true
                this.dirtyClose = true
                setTimeout(() => this.saveSuccess = false, 5000)
              })
              .catch((err) => {
                this.saving = false
                this.saveFailed = true;
                setTimeout(() => this.saveFailed = false, 5000)
                apiErrorHandler(err)
              })
          } else {
            // This is a save
            this.$api.createUser({ ...this.editingUser, account_id: this.account.id })
              .then(() => {
                this.saving = false
                this.dirtyClose = true
                this.userCreated = true
                // Emit the close to close the modal on creates. Put user back on the list page
                this.handleClose();
              })
              .catch((err) => {
                // Catch conflit errors for existing email. Split on new line to only get the message.
                if (err.response.data.exception) {
                  this.userErrorMessage = err.response.data.exception.split('\n')[0];
                }
                this.saving = false
                this.saveFailed = true;
                setTimeout(() => {
                  this.saveFailed = false;
                  this.userErrorMessage = defaultMessage;
                }, 5000)
                apiErrorHandler(err)
              })
          }
        }
      },
    },
  }
</script>

<style scoped>
  .min-width {
    min-width: 7rem;
  }

  .permissions-columns {
    grid-template-columns: 1fr fit-content(20%);
  }
</style>
