import accountActions from './accountActions';
import userActions from './userActions';
import KeywordTopicActions from "@/store/keywordTopicActions";
import topicActions from './topicActions';
import domainAppendActions from "@/store/domainAppendActions";
import shipperActions from "@/store/shipperActions";
import releaseActions from "@/store/releaseActions";
import tihaActions from "@/store/tihaActions";

export default api => {
    return {
        // Account Controller Actions
        ...accountActions(api),
        // User Controller Actions
        ...userActions(api),
        // Keyword to Topic Controller Actions
        ...KeywordTopicActions(api),
        // Topic Taxonomy Actions
        ...topicActions(api),
        // Domain Append Controller Actions
        ...domainAppendActions(api),
        // Shipper Controller Actions
        ...shipperActions(api),
        // Release Controller Actions
        ...releaseActions(api),
        // TIHA Controller Actions
        ...tihaActions(api),
    }
}
