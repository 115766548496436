<template>
  <div>
    <div class="flex">
      <div class="flex-none pr-12 w-1/4">
        <nav>
          <ul class="pl-2">
            <router-link :to="{ name: 'AccountInfo', params: { id: `${account.id}` }}"
                         custom
                         v-slot="{ href, navigate, isActive, isExactActive }">
              <a :href="href" @click="navigate">
              <li :class="['nav-item', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                Account Information
              </li>
              </a>
            </router-link>
            <router-link :to="{ name: 'ManageUsers', params: { id: `${account.id}` }}">
              <a :href="href" @click="navigate" :class="isDisabled()  ? 'disabled' : ''">
              <li :class="['nav-item', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                Users
              </li>
              </a>
            </router-link>
            <router-link :to="{ name: 'ProductDab', params: { id: `${account.id}` }}"
                         custom
                         v-slot="{ href, navigate, isActive, isExactActive }">
              <a :href="href" @click="navigate" :class="isDisabled()  ? 'disabled' : ''">
              <li :class="['nav-item', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                DAB
              </li>
              </a>
            </router-link>
            <router-link :to="{ name: 'ProductSurge', params: { id: `${account.id}` }}"
                         custom
                         v-slot="{ href, navigate, isActive, isExactActive }">
              <a :href="href" @click="navigate" :class="isDisabled()  ? 'disabled' : ''">
              <li :class="['nav-item', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                Surge
              </li>
              </a>
            </router-link>
            <router-link :to="{ name: 'ProductAudienceVerification', params: { id: `${account.id}` }}"
                         custom
                         v-slot="{ href, navigate, isActive, isExactActive }">
              <a :href="href" @click="navigate" :class="isDisabled() ? 'disabled' : ''">
              <li :class="['nav-item', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                Audience Verification
              </li>
              </a>
            </router-link>
            <router-link :to="{ name: 'ProductVisitorInsights', params: { id: `${account.id}` }}"
                         custom
                         v-slot="{ href, navigate, isActive, isExactActive }">
              <a :href="href" @click="navigate" :class="isDisabled()  ? 'disabled' : ''">
              <li :class="['nav-item', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                Visitor Insights
              </li>
              </a>
            </router-link>
            <router-link :to="{ name: 'ProductVIFeed', params: { id: `${account.id}` }}"
                         custom
                         v-slot="{ href, navigate, isActive, isExactActive }">
              <a :href="href" @click="navigate" :class="isDisabled()  ? 'disabled' : ''">
              <li :class="['nav-item', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                VI Feed
              </li>
              </a>
            </router-link>
            <router-link :to="{ name: 'ProductRTVI', params: { id: `${account.id}` }}"
                         custom
                         v-slot="{ href, navigate, isActive, isExactActive }">
              <a :href="href" @click="navigate" :class="isDisabled()  ? 'disabled' : ''">
              <li :class="['nav-item', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                VI, Real-Time Capabilities
              </li>
              </a>
            </router-link>
          </ul>
        </nav>
      </div>

      <div class="flex-1">
        <router-view :loading="loading" :account="account" @account-updated="updateAccountReference"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import { useRoute } from "vue-router";

  // This page is general navigation and styling for all sub pages for account management.
  export default {
    name: "AccountManagementPage",
    components: {},
    data() {
      return {
        account: {},
        loading: true
      }
    },
    methods: {
      isDisabled: function() {
        return this.account.id <= 0;
      },
      updateAccountReference(account) {
        // Update the route with the new account id and force a re-mount.
        // TODO this is a potential bug. The accountUpdated event can be fired by any subpage,
        // but this route update pushes you to the information page
        this.$router.push(`/account/${account.id}/information`)
        this.account = account;
      }
    },
    mounted() {
      let id = useRoute().params.id
      if (id === "0") {
        this.account = { id: 0 };
        this.loading = false;
        return;
      }

      this.$api.getAccount(id)
        .then((res) => {
          this.account = res.data || {};
          this.loading = false;
        })
        .catch((err) => console.error(err.message))
    }
  };
</script>

<style lang="css" scoped>
  .nav-item {
    @apply my-1 py-3 pl-10 pr-4 text-gray rounded-md hover:bg-gray-lightest hover:text-black cursor-pointer;
  }

  .nav-item:hover,
  .nav-item.router-link-active {
    @apply font-bold bg-gray-lightest text-black;
  }

  .disabled {
    pointer-events: none;
    opacity: .5;
  }
</style>
