<template>
  <toggle :disabled="disabled"
          :checked="modelValue"
          :modelValue="modelValue"
          @update:modelValue="onChange"
          >
    <template v-if="showStatusText" #leftLabel>
      {{ modelValue ? "Enabled" : "Disabled" }}
    </template>
  </toggle>
</template>

<script>
  import { Toggle } from "@bombora/component-library";

  export default {
    name: "EnableStatusToggle",
    components: { Toggle },
    emits: ["update:modelValue"],
    props: {
      modelValue: {},
      disabled: {
        type: Boolean,
        default: false,
      },
      showStatusText: {
        type: Boolean,
        default: true
      },
    },
    methods: {
      onChange(event) {
        console.log(event);
        this.$emit("update:modelValue", event)
      }
    },
    mounted() {
    }
  };
</script>
