import { createWebHistory, createRouter } from "vue-router"
import { routeGuard } from "@/services/auth"
import NotFound from "@/components/pages/NotFound.vue"
import LoginCallback from "@/components/pages/LoginCallback.vue"
import Unauthorized from "@/components/pages/Unauthorized.vue"
import Layout from "@/components/layout/Layout.vue"

import AccountList from "@/components/pages/account/List.vue"
import AccountSettingsPage from "@/components/pages/account/AccountSettingsPage.vue"
import AccountInfo from "@/components/pages/account/Information.vue"
import ProductSurge from "@/components/pages/account/ProductSurge.vue"
import ProductDab from "@/components/pages/account/ProductDab.vue"
import ProductVisitorInsights from "@/components/pages/account/ProductVisitorInsights.vue"
import ProductAudienceVerification from "@/components/pages/account/ProductAudienceVerification.vue"
import ProductVIFeed from "@/components/pages/account/ProductVIFeed.vue"
import ProductRTVI from "@/components/pages/account/ProductRTVI.vue"
import ManageUsers from "@/components/pages/account/ManageUsers.vue"

import Taxonomy from "@/components/pages/Taxonomy.vue"

import DomainAppend from "@/components/pages/DomainAppend.vue"

import KeywordToTopic from "@/components/pages/KeywordToTopic.vue"
import Shipper from "@/components/pages/Shipper.vue"


import Release from "@/components/pages/Release.vue"

import TIHA from "@/components/pages/TIHA.vue"

const routes = [
    {
        path: "/",
        component: Layout,
        redirect: "/account/list",
        children: [
            {
                path: "/account/list",
                name: "AccountList",
                component: AccountList,
                beforeEnter: [routeGuard],
            },
            {
                path: "/account/:id",
                component: AccountSettingsPage,
                redirect: "/information",
                beforeEnter: [routeGuard],
                children: [
                    {
                        path: "information",
                        name: "AccountInfo",
                        component: AccountInfo,
                    },
                    {
                        path: "users",
                        name: "ManageUsers",
                        component: ManageUsers,
                    },
                    {
                        path: "product/surge",
                        name: "ProductSurge",
                        component: ProductSurge,
                    },
                    {
                        path: "product/dab",
                        name: "ProductDab",
                        component: ProductDab
                    },
                    {
                        path: "product/visitorinsights",
                        name: "ProductVisitorInsights",
                        component: ProductVisitorInsights
                    },
                    {
                        path: "product/audienceverification",
                        name: "ProductAudienceVerification",
                        component: ProductAudienceVerification
                    },
                    {
                        path: "product/vifeed",
                        name: "ProductVIFeed",
                        component: ProductVIFeed
                    },
                    {
                        path: "product/rtvi",
                        name: "ProductRTVI",
                        component: ProductRTVI
                    },
                ]
            },
        ]
    },
    {
        path: "/Release",
        component: Layout,
        redirect: "/release/manage",
        children: [
            {
                path: "/release/manage",
                name: "Release",
                component: Release,
                beforeEnter: [routeGuard],
            },
        ]
    },
    {
        path: "/LoginCallback",
        name: "LoginCallback",
        component: LoginCallback
    },
    {
        path: "/taxonomy",
        component: Layout,
        redirect: "/taxonomy/manage",
        children: [
            {
                path: "/taxonomy/manage",
                name: "Taxonomy",
                component: Taxonomy,
                beforeEnter: [routeGuard],
            },
        ]
    },
    {
        path: "/Shipper",
        component: Layout,
        redirect: "/Shipper/manage",
        children: [
            {
                path: "/Shipper/manage",
                name: "Shipper",
                component: Shipper,
                beforeEnter: [routeGuard],
            },
        ]
    },
    {
        path: "/DomainAppend",
        component: Layout,
        redirect: "/DomainAppend/manage",
        children: [
            {
                path: "/DomainAppend/manage",
                name: "DomainAppend",
                component: DomainAppend,
                beforeEnter: [routeGuard],
            },
        ]
    },
    {
        path: "/KeywordToTopic",
        component: Layout,
        redirect: "/KeywordToTopic/manage",
        children: [
            {
                path: "/KeywordToTopic/manage",
                name: "KeywordToTopic",
                component: KeywordToTopic,
                beforeEnter: [routeGuard],
            },
        ]
    },
    {
        path: "/tiha",
        component: Layout,
        redirect: "/tiha/manage",
        children: [
            {
                path: "/tiha/manage",
                name: "TIHA",
                component: TIHA,
                beforeEnter: [routeGuard],
            },
        ]
    },
    {
        path: "/Unauthorized",
        name: "Unauthorized",
        component: Unauthorized
    },
    {
        path: "/:pathMatch(.*)",
        component: NotFound,
    },
]

const router = createRouter({
    linkExactActiveClass: 'active',
    // History mode, vs Hash mode
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return {}
        }
        // The vue router docs are apparently wrong, and say this should be { x: 0, y: 0 }, which doesn't work.
        // https://stackoverflow.com/questions/54535838/scroll-behaviour-vuejs-not-working-properly
        return { left: 0, top: 0 }
    },
})

export default router
