<template>
  <column-group :cols="wideLabel ? 3 : 2" class="mt-2">
    <div class="flex flex-col justify-center content-center" :class="{ 'col-span-2': wideLabel }">
      <div class="font-bold leading-4">
        {{ label }}
        <span v-if="required">*</span>
        <tooltip v-if="hasHelpText">
          <slot name="helpText"></slot>
        </tooltip>
      </div>
      <div v-if="hasDetails" class="text-gray-light text-sm pt-1">
        <slot name="details"></slot>
      </div>
    </div>
    <div class="text-right">
      <div class="float-right">
        <slot></slot>
      </div>
    </div>
  </column-group>
  <div class="h-5 mb-3 text-right">
    <slot name="validation-message"></slot>
  </div>
</template>
<script>
  import ColumnGroup from "@bombora/component-library/dist/ColumnGroup";
  import Tooltip from "@bombora/component-library/dist/Tooltip";

  export default {
    name: "ValidatedFieldGroup",
    components: {
      ColumnGroup,
      Tooltip,
    },
    props: {
      // This is an exception to the no-content-in-props rule for developer experience
      label: {
        type: String,
        default: "MISSING FIELD LABEL",
        required: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      // If we have a wide details or label, we can make the columns smaller and shrink the input side
      wideLabel: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      hasHelpText() {
        return !!this.$slots.helpText;
      },
      hasDetails() {
        return !!this.$slots.details;
      }
    },
    mounted() { },
    methods: {},
  };
</script>

<style lang="css" scoped></style>
