import axios from "axios"

export const setupApi = async () => {
  const api = getTokenCb => {
    if (getTokenCb == null) {
      console.error(
          "Auth0 has not been set up yet. You cannot initialize the API without first setting up Auth0"
      )
    }
    // Token is initialized in each method to prevent closure capturing of the token variable.
    return {
      get: async ({ url, options }) => {
        try {
            const token = await getTokenCb()
          return await axios.get(url, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            ...options,
          })
        } catch (error) {
          return parseErrorResponse(error)
        }
      },

      post: async ({ url, data, options }) => {
        try {
          const token = await getTokenCb()
          return axios.post(url, data, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            ...options,
          })
        } catch (error) {
          return parseErrorResponse(error)
        }
      },

      postForm: async ({ url, data, options }) => {
          try {
              const token = await getTokenCb();
              return await axios.post(url, data, {
                  headers: {
                      Accept: "application/json",
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "multipart/form-data"
                  },
                  ...options
              })
          } catch (error) {
              return parseErrorResponse(error)
          }
      },

      put: async ({ url, data, options }) => {
        try {
          const token = await getTokenCb()
          return axios.put(url, data, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            ...options,
          })
        } catch (error) {
          return parseErrorResponse(error)
        }
        },

        patch: async ({ url, data, options }) => {
            try {
                const token = await getTokenCb()
                return axios.patch(url, data, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    ...options,
                })
            } catch (error) {
                return parseErrorResponse(error)
            }
        },

      delete: async ({ url, options }) => {
        try {
          const token = await getTokenCb()
          return axios.delete(url, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            ...options,
          })
        } catch (error) {
          return parseErrorResponse(error)
        }
      }
    }
  }

  return {
    install: app => {
      app.config.globalProperties.$apiService = api(app.config.globalProperties.$auth.getTokenSilently)
    },
  }
}

function parseErrorResponse(error) {
  if (!error.response) {
    throw error
  }

  return {
    data: error.response.data,
    statusCode: error.response.status,
    message: error.response.message
  }
}
