export default api => {
    const uri = `/tiha`;

    return {
        runTIHAJob: async (tihaRequest) =>
            (await api.postForm({ url: `${uri}/tiha`, data: tihaRequest }).then((res) => res.data)),
        runControlJob: async (controlRequest) =>
            (await api.postForm({ url: `${uri}/control`, data: controlRequest }).then((res) => res.data)),
    }
}
