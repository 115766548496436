<template>
  <div>
    <!-- loading spinner -->
    <div v-if="loading" class="text-center">
      <div class="inline-block">
        <loading-spinner />
      </div>
    </div>

    <div v-if="!loading && account">
      <account-breadcrumbs :account="account" />

      <form @submit.prevent="onSubmit">
        <card>
          <template #header>
            <div class="flex">
              <manage-page-heading heading="Account Information"></manage-page-heading>
            </div>
          </template>

          <validated-field-group label="Name" required>
            <text-field v-model="capturedAccount.name" name="account.name" />
            <template #validation-message>
              <FieldErrorMessage :field="v$.capturedAccount.name" />
            </template>
          </validated-field-group>

          <validated-field-group label="Salesforce Account Id">
            <text-field v-model="capturedAccount.salesforceAccountId" name="account.salesforceAccountId" />
            <template #validation-message>
              <FieldErrorMessage :field="v$.capturedAccount.salesforceAccountId" />
            </template>
          </validated-field-group>

          <validated-field-group label="Entity Type" required>
            <dropdown :items="entityTypes" v-model="capturedAccount.type" name="account.type" />
            <template #validation-message>
              <FieldErrorMessage :field="v$.capturedAccount.type" />
            </template>
          </validated-field-group>

          <validated-field-group label="Priority" required>
            <text-field v-model="capturedAccount.priority" placeholder="0 - 1.0" name="account.priority" />
            <template #validation-message>
              <FieldErrorMessage :field="v$.capturedAccount.priority" />
            </template>
          </validated-field-group>

          <template v-slot:footer>
            <btn type="submit" color="orange" :is-disabled="saving">
              {{ buttonText }}
            </btn>
            <Transition>
              <alert v-if="saveSuccess" style-type="success" class="mt-4">
                Your account information was updated successfully.
              </alert>
            </Transition>
            <Transition>
              <alert v-if="saveFailed" style-type="warning" class="mt-4">
                There was a problem saving your changes. Please try again later.
              </alert>
            </Transition>
            <Transition>
              <alert v-if="formInvalid" style-type="warning" class="mt-4">
                Please correct the errors in the form and resubmit.
              </alert>
            </Transition>
          </template>
        </card>
      </form>
    </div>
  </div>
</template>

<script>
  import { Alert, Btn, Card, Dropdown, TextField } from "@bombora/component-library";

  import AccountBreadcrumbs from "@/components/shared/AccountBreadcrumbs";
  import ManagePageHeading from "@/components/shared/ManagePageHeading";
  import FieldErrorMessage from "@/components/shared/FieldErrorMessage";
  import ValidatedFieldGroup from "@/components/shared/ValidatedFieldGroup.vue";

  import { convertDictToList } from '@/helpers.js';

  import { useVuelidate } from '@vuelidate/core'
  import { required, minValue, maxValue, helpers } from '@vuelidate/validators'

  export default {
    name: "AccountInformation",
    components: {
      AccountBreadcrumbs,
      Alert,
      Btn,
      Card,
      Dropdown,
      FieldErrorMessage,
      ManagePageHeading,
      TextField,
      ValidatedFieldGroup,
    },
    emits: ["account-updated"],
    setup: () => ({ v$: useVuelidate() }),
    // inherited from pages/account/Page
    props: ["account", "loading"],
    data() {
      return {
        capturedAccount: this.account,
        entityTypes: [],
        showModal: false,
        saving: false,
        saveSuccess: false,
        saveFailed: false,
        formInvalid: false,
      };
    },
    validations() {
      return {
        capturedAccount: {
          name: {
            required: helpers.withMessage('Account Name is required', required),
          },
          priority: {
            required,
            minValue: helpers.withMessage('Priority must be greater than or equal to zero', minValue(0)),
            maxValue: helpers.withMessage('Priority cannot be greater than 1', maxValue(1)),
          },
          type: {
            required: helpers.withMessage('Entity Type is required', required),
          }
        }
      }
    },
    watch: {
      account: {
        handler: "getDataFromAccount",
        immediate: true
      }
    },
    mounted() {
      this.getDataFromAccount()
      this.$api.getAccountTypes()
        .then((res) => {
          this.entityTypes = convertDictToList(res.data);
        })
    },
    methods: {
      async onSubmit() {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          console.debug(this.v$.$errors);
          this.formInvalid = true;
          setTimeout(() => this.formInvalid = false, 5000)
          return
        }
        else {
          this.saving = true
          let writeAccount = this.account.id <= 0 ? this.saveNewAccount : this.updateAccount;
          writeAccount(this.capturedAccount)
            .then((res) => {
              this.saving = false;
              console.log(res.data);
              this.$emit('account-updated', res.data)
              this.saveSuccess = true;
              setTimeout(() => this.saveSuccess = false, 5000);
            })
            .catch((err) => {
              this.saving = false;
              this.saveFailed = true;
              setTimeout(() => this.saveFailed = false, 5000);
              console.error(err.message);
            })
        }
      },
      saveNewAccount(values) {
        return this.$api.createAccount(values);
      },
      updateAccount(values) {
        return this.$api.updateAccount(this.account.id, values);
      },
      closeModal() {
        this.showModal = false;
      },
      getDataFromAccount() {
        if (this.account) {
          this.capturedAccount = Object.assign({}, this.account);
        }
      },
    },
    computed: {
      buttonText() {
        if (this.saving) return "Saving...";
        if (this.account.id <= 0) {
          return "Add New Account"
        }
        return "Save";
      }
    }
  }
</script>

<style scoped>
  .router-link-exact-active {
    background-color: red;
  }
</style>
