export default api => {
    const uri = `/shipper`;

    return {
        getShipperList: async (listParams) => {
            return (await api.post({ url: `${uri}/getlist`, data: listParams }).then((res) => res))
        },
        submitRetry: async(ids) => {
            return (await api.post({ url: `${uri}/retryrequest`, data: ids }).then((res) => res))
        }
    }
}
