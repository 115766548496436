export default api => {
    const uri = `/taxonomy`;

    return {
        getTopicHierarchy: async () => {
            const url = `${uri}/GetTopicHierarchy`;
            return api.get({
                url: url,
                }).then((res) => res.data)
        },
        saveTopic: async (topic) =>
            (await api.post({ url: `${uri}`, data: topic }).then((res) => res.data)),
        deleteTopic: async (id) =>
            (await api.delete({ url: `${uri}/${id}` }).then((res) => res.data)),
    }
}
