export default api => {
    const uri = `/keywordTopics`;

    return {
        getRequests: async () => {
            return (await api.get({url: `${uri}/requests`}).then((res) => res))
        },

        downloadFile: async (fileId) => {
            let response = await api.get({url: `${uri}/downloadFile/${fileId}`})
            if (response.data.success == false) {
                return response.data;

            } else {
                console.log(response);
                const blob = new Blob([response.data], { type: 'text/csv' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = `KeywordToTopics-${new Date().toISOString().split('T')[0]}.csv`
                link.click()
                URL.revokeObjectURL(link.href)
                return {success: true}
            }
        },

        submitRequest: async(file) => {
            return (await api.postForm({url: `${uri}/submitRequest`, data: file}).then((res) => res))
        }


    }
}
